export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const formatDate = ( date: string) => {
    return new Date(date).toISOString().split('T')[0]
 }
 export const formatPrice = ( price: number, locale: string, currency: string, minDigits: number, maxDigits: number) => {
    return Number(price).toLocaleString(locale, {
       style: 'currency',
       currency: currency,
       minimumFractionDigits: minDigits,
       maximumFractionDigits: maxDigits,
     })
 }
