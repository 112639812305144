/* eslint-disable react-hooks/rules-of-hooks */
export const userHasRole = ( roles : string[], userRoles: any  ) => {
    let hasRole: boolean = false;
    roles.forEach(element => {

      if (userRoles.some( ( e: any ) => e.code === element)) {
        hasRole = true;
      }
    });
   return hasRole;
}