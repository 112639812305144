import { useState, useEffect } from 'react'
import { UserSelectModel } from '../../../modules/auth/models/UserSelectModel'
import { useHistory } from 'react-router-dom'
import { obtenerPaquetes,getCatalog,getDataPackageById } from '../../../service/paquetes/Paquetes'
import { PackageModel } from '../../../modules/auth/models/PackageModel'
export const useGetAll = (
    realoadGrid: number,
    filters: any
  ) => {
    const [data, setData] = useState<any[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        setLoadingRequest(0);
        const res = await obtenerPaquetes()
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          setData(res.rows);
          setLoadingRequest(1);
        }
      };
      if (realoadGrid) {
        fetchPost();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realoadGrid]);
    return { data, loadingRequest };
  };


  export const useSelectComponentFormat = (code: string, execute: boolean) => {
    const [optionsSelect, setOptionSelect] = useState<any[]>([]);
  
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        const data = await getCatalog(code)
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (data) {
          const selectOptions = data.rows.map((option: any) => ({
            value: option.id,
            label: option.name,
          }));
          setOptionSelect(selectOptions);
        }
      };
  
      if (execute) {
        fetchPost();
      }
    }, [execute]);
  
    return { optionsSelect };
  };

  export const useGetOne = (id: number) => {
    const initModel = {
      id: 0,
      packageName: '',
      price: 0,
      classPackage: 0,
      daysPackage: 0,
      status: 0
    };
    const [initValues, setInitValues] = useState<PackageModel>(initModel);
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        const res = await getDataPackageById(id)
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          setInitValues(res);
        }
      };
      if (id) {
        fetchPost();
      } else {
        setInitValues(initModel);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return { initValues };
  };