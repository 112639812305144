import React, { useState, useEffect } from 'react';
import { dateFormatt } from '../../../../../rbts/helpers/FormatDate';
import { KTSVG, LoadingGrid, TableList } from '../../../../../rbts/helpers';
import { DownloadExcel } from '../helpers/downloadExcel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetAll } from '../hooks/VigenciasHook';
import moment from 'moment';
import { formattedCurrency } from '../../../../../rbts/helpers/FormatCurrency';

type Props = {};
let initSales: any = {
  fechaInicio: '',
  fechaFin: '',
};

const View: React.FC<Props> = () => {
  const [loadingView, setloadingView] = useState(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [filters, setFilters] = useState({
    fechaInicio: '',
    fechaFin: '',
  });

  const { data, loadingRequest } = useGetAll(reloadGrid, filters);

  const formatDateForInput = (date: any) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD');
  };
  const handleDateChangeInicio = (event: any) => {
    const { value } = event.target;
    formikSales.setFieldValue('fechaInicio', value);
  };
  const handleDateChangeFin = (event: any) => {
    const { value } = event.target;
    formikSales.setFieldValue('fechaFin', value);
  };

  const download = () => {
    let columnsName = [
      'Fecha',
      'Nombre del Paquete',
      'Fecha de vigencia',
      // 'Cliente',
      'Estatus',
      'Clases',
      'Monto',
      'Cliente',
    ];
    let dataExcel: any = data.map((item) => {
      return {
        fecha: item.packageUserPurchase.purchase_date
          ? new Date(item.packageUserPurchase.purchase_date ?? '')
              .toISOString()
              .split('T')[0]
          : 'Sin fecha',
        packName: item.packageUserP.package_name,
        fechaVigencia: item.date_validation_end
          ? new Date(item.date_validation_end ?? '')
              .toDateString()
              .split('T')[0]
          : 'Sin vigencia',
        // userPurchase: item.packageUserU.first_name,
        estatus: item.status,
        clases: item.class_package,
        monto: item.packageUserPurchase.amount,
        cliente: item.packageUserU.first_name,
      };
    });
    const response = {
      sheetName: 'Vigencias',
      fileName: 'reporte_vigencias.xlsx',
      title: 'Reporte de Vigencias',
      columnsName: columnsName,
      columns: [
        {
          key: 'fecha',
          width: 20,
        },
        {
          key: 'packName',
          width: 20,
        },
        {
          key: 'fechaVigencia',
          width: 20,
        },
        // {
        //   key: 'userPurchase',
        //   width: 10,
        // },
        {
          key: 'estatus',
          width: 10,
        },
        {
          key: 'clases',
          width: 10,
        },
        {
          key: 'monto',
          width: 10,
        },
        {
          key: 'cliente',
          width: 10,
        },
      ],
      data: dataExcel,
    };
    DownloadExcel(response);
  };

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const columns: any = [
    {
      id: 'fecha',
      name: <div className="fw-bolder text-muted me-5">Fecha</div>,
      selector: (row: any) => row.packageUserPurchase.purchase_date,
      cell: (row: any) =>
        row.packageUserPurchase
          ? dateFormatt(row.packageUserPurchase.purchase_date)
          : 'Sin registro',
      sortable: true,
    },
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre del Paquete</div>,
      // selector: (row: any) =>
      //   row.packageUserP ? row.packageUserP.package_name : 'Sin registro',
      selector: (row: any) => row.packageUserP.package_name,
      cell: (row: any) => (
        <>
          {row.packageUserP ? (
            <div className="fw-bolder">{row.packageUserP.package_name}</div>
          ) : (
            <div className="text-muted">Sin registro</div>
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'vigencia',
      name: <div className="fw-bolder text-muted me-5">Fecha de vigencia</div>,
      selector: (row: any) => row.date_validation_end,
      cell: (row: any) =>
        row.date_validation_end
          ? dateFormatt(row.date_validation_end)
          : 'Sin registro',
      sortable: true,
    },
    {
      id: 'estatus',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      selector: (row: any) => row.status,
      cell: (row: any) => (
        <>
          {row.status === 'VIGENTE' ? (
            <span className="badge badge-light-primary">Vigente</span>
          ) : row.status === 'VENCIDO' ? (
            <span className="badge badge-light-danger">Vencido</span>
          ) : (
            <span className="badge badge-light-dark">Sin registro</span>
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'clases',
      name: <div className="fw-bolder text-muted me-5">Clases</div>,
      selector: (row: any) =>
        row.class_package ? row.class_package : 'Sin registro',
      sortable: true,
    },
    {
      id: 'monto',
      name: <div className="fw-bolder text-muted me-5">Monto</div>,
      selector: (row: any) => Number(row.packageUserPurchase.amount),
      cell: (row: any) => (
        <>
          {row.packageUserPurchase.amount ? (
            <div className="text-gray-900 ">
              {formattedCurrency(row.packageUserPurchase.amount)}
            </div>
          ) : (
            <div className="text-muted">Sin registro</div>
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'cliente',
      name: <div className="fw-bolder text-muted me-5">Cliente</div>,
      selector: (row: any) =>
        row.packageUserU ? row.packageUserU.first_name : 'Sin registro',
      sortable: true,
    },
  ];

  const salesSchema = Yup.object().shape({
    fechaInicio: Yup.string()
      .required('Fecha de inicio es requerida.')
      .test(
        'is-valid-date',
        'Fecha de inicio no es una fecha válida.',
        (value) => moment(value, 'YYYY-MM-DD', true).isValid()
      ),
    fechaFin: Yup.string()
      .required('Fecha fin es requerida.')
      .test('is-valid-date', 'Fecha fin no es una fecha válida.', (value) =>
        moment(value, 'YYYY-MM-DD', true).isValid()
      )
      .test(
        'is-after-fechaInicio',
        'La fecha final no puede ser menor que fecha inicial.',
        function (value) {
          const { fechaInicio } = this.parent;
          return moment(value).isSameOrAfter(moment(fechaInicio));
        }
      ),
    // .test(
    //   'is-before-today',
    //   'No puede ser mayor a la fecha actual.',
    //   (value) => moment(value).isSameOrBefore(moment(), 'day')
    // ),
  });

  const formikSales = useFormik({
    initialValues: initSales,
    validationSchema: salesSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setFilters(values);
    },
  });

  return (
    <>
      <div className="row gy-5 g-xl-8 salesPageView">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <form
              id="kt_search_sales_form"
              className="form"
              action="#"
              onSubmit={formikSales.handleSubmit}
            >
              <div className="card-header border-0 pt-5">
                <div className="align-items-start flex-column col-12">
                  <div className="col-12">
                    <div className="row header-grid-wrapper">
                      <div className="col-1">
                        <label className="fw-bold">Desde:</label>
                      </div>

                      <div className="col-3">
                        <input
                          placeholder="Nombre"
                          className="form-control"
                          type="date"
                          name="fechaInicio"
                          value={formatDateForInput(
                            formikSales.values.fechaInicio
                          )}
                          onChange={handleDateChangeInicio}
                        />
                        {formikSales.touched.fechaInicio &&
                          formikSales.errors.fechaInicio && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formikSales.errors.fechaInicio}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className="col-1">
                        <label className="fw-bold">Hasta:</label>{' '}
                      </div>

                      <div className="col-3">
                        <input
                          placeholder="Nombre"
                          className="form-control"
                          type="date"
                          name="fechaFin"
                          value={formatDateForInput(
                            formikSales.values.fechaFin
                          )}
                          onChange={handleDateChangeFin}
                        />
                        {formikSales.touched.fechaFin &&
                          formikSales.errors.fechaFin && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formikSales.errors.fechaFin}
                              </span>
                            </div>
                          )}
                      </div>

                      <div className="buttons-wrapper col-4">
                        <button
                          type="submit"
                          className="btn btn-sm btn-light-primary"
                          data-bs-placement="top"
                          data-bs-trigger="hover"
                          title="Buscar"
                          id="kt_toolbar_primary_button"
                          onClick={() => {
                            // addPayment();
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen004.svg"
                            // className="svg-icon-excel"
                          />
                          Buscar
                        </button>

                        <a
                          className="btn btn-sm btn-light-success"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-trigger="hover"
                          title="Descargar Excel"
                          id="kt_toolbar_primary_button"
                          onClick={() => {
                            download();
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/files/fil029.svg"
                            className="svg-icon-excel"
                          />
                          Descargar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
