import React, { FC } from 'react';
import { PageTitle, PageLink } from '../../../rbts/layout/core';
import { View } from './components/View';

const widgetsBreadCrumbs: Array<PageLink> = [];

const UsuariosPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={widgetsBreadCrumbs}>Usuarios</PageTitle>
      <View />
    </>
  );
};

export { UsuariosPage };
