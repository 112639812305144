import { useState, useEffect } from 'react';
// import { getApiKeysRelations } from '../../../service/catalog/Apikey';
// import { ApiKeyModel } from '../models/ApiKeyModel';
import { useHistory } from 'react-router-dom';
import { getSalesReport } from '../../../../service/reportes/Sales';

export const useGetAll = (realoadGrid: number, filters: any) => {
  const [data, setData] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getSalesReport(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid, filters]);
  return { data, loadingRequest };
};
