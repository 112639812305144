import React, { FC } from 'react';

import { PageTitle, PageLink } from '../../../rbts/layout/core';
import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [];

const ClasesPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Clases</PageTitle>
      <View />
    </>
  );
};

export { ClasesPage };
