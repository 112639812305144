import React, { useState, useEffect } from 'react';
import { MenuComponent } from '../../../../rbts/assets/ts/components/MenuComponent';
import { useGetAll } from '../hooks/clasesHook';
import {
  formatHourGrid,
  dateFormatt,
} from '../../../../rbts/helpers/FormatDate';
import { KTSVG, LoadingGrid, TableList } from '../../../../rbts/helpers';
import { CreateClassModal } from '../_modals/CreateClassModal';
import { Dropdown } from 'bootstrap';

type Props = {};

const View: React.FC<Props> = () => {
  const [filters] = useState({});
  const [loadingView, setloadingView] = useState(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({});
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [classTitleModal, setClassTitleModal] = useState('');
  const [idClassSend, setIdClassSend] = useState(0);
  const updateClass = (id: number, info: any) => {
    setReloadGrid(Math.random() * 40);
    setClassTitleModal('Editar clase');
    setVisivilityModal(!visivilityModal);
    setIdClassSend(id);
  };

  const addClass = () => {
    setReloadGrid(Math.random() * 40);
    setClassTitleModal('Agregar clase');
    setVisivilityModal(!visivilityModal);
  };

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
    // document
    //   .querySelectorAll('[data-bs-toggle="dropdown"]')
    //   .forEach((dropdown) => {
    //     new Dropdown(dropdown);
    //   });
  }, [loadingRequest]);
  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 500);
  const dropdownRender = (info: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <a
                className="dropdown-item"
                onClick={() => updateClass(info.id, info)}
              >
                Editar
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  };

  const columns: any = [
    {
      id: 'class_name',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.className,
      sortable: true,
    },
    {
      id: 'typeClass.id',
      name: <div className="fw-bolder text-muted me-5">Tipo</div>,
      selector: (row: any) => row.typeClass.name,
      sortable: true,
    },
    {
      id: 'instructor.id',
      name: <div className="fw-bolder text-muted me-5">Instructor</div>,
      selector: (row: any) => row.instructor.first_name,
      sortable: true,
    },
    {
      id: 'class_time',
      name: <div className="fw-bolder text-muted me-5">Dia</div>,
      selector: (row: any) => row.classTime,
      cell: (row: any) => dateFormatt(row.classTime),
      sortable: true,
    },
    {
      id: 'class_time',
      name: <div className="fw-bolder text-muted me-5">Hora</div>,
      selector: (row: any) => row.classTime,
      cell: (row: any) => formatHourGrid(row.classTime),
      sortable: true,
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={() => {
                    addClass();
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      {/* MODALS */}
      <CreateClassModal
        idClass={idClassSend}
        title={classTitleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setloadingView(true);
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
          setIdClassSend(0);
        }}
      ></CreateClassModal>
    </>
  );
};

export { View };
