import React, { useEffect, useState } from 'react';
import { KTSVG, SimpleAlert } from '../../../../rbts/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addClass, updateClass } from '../../../service/clases/Clases';
import { useGetOne, useGetInstructorSelect } from '../hooks/clasesHook';
import Select from 'react-select';
import { styleSelect } from '../../../../rbts/helpers/SelectStyle';
import { useSelectComponentFormat } from '../../../../rbts/hooks/genericoHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  idClass: number;
};

const CreateClassModal: React.FC<Props> = ({
  idClass,
  show,
  handleClose,
  title,
}) => {
  const { initValues } = useGetOne(idClass);
  const [loading, setLoading] = useState(false);
  const { dataInstructorSelect } = useGetInstructorSelect();
  const [instructor, setInstructor] = useState<any | null>(null);
  const [typeClassSelect, setTypeClassSelect] = useState<any | null>(null);
  const [makeCatRequests, setMakeCatRequests] = useState<boolean>(false);
  const { optionsSelect: type_class } = useSelectComponentFormat(
    'TYC',
    makeCatRequests
  );
  const classSchema = Yup.object().shape({
    className: Yup.string()
      .max(40, 'El nombre no debe tener más de 40 caracteres')
      .required('Nombre es requerido.'),
    typeClassId: Yup.number()
      .required('Tipo de clase es requerido.')
      .min(1, 'Debe seleccionar un tipo de clase.'),
    instructorId: Yup.number()
      .required('Instructor es requerido.')
      .min(1, 'Debe seleccionar un instructor.'),
    classTime: Yup.date()
      // .nullable(true) // Permite que el campo sea null
      // .notRequired() // Hace que el campo sea opcional
      .min(new Date(), 'La fecha y hora no pueden ser anteriores a la actual')
      .required('Fecha y hora son requeridas'),
    registrationLimit: Yup.string()
      .matches(/^[1-9]\d{0,3}$/, 'Limite de incripciones es requerido')
      .required('Requerido'),
  });
  const formik = useFormik({
    initialValues: initValues,
    validationSchema: classSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        // setLoading(false)

        if (values.id > 0) {
          updateClass(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              setLoading(false);
              handleCloseModal(false);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        } else {
          addClass(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              setLoading(false);
              handleCloseModal(false);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });

  const formatDateForInput = (date: any) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleDateChange = (event: any) => {
    const value = event.target.value;
    const date = new Date(value);
    formik.setFieldValue('classTime', date.toISOString());
  };

  const handleCloseModal = (updateGrid: boolean) => {
    formik.resetForm();
    setInstructor(null);
    setTypeClassSelect(null);
    handleClose(true);
  };
  const handleSelect = (selectedOption: any) => {
    const { value } = selectedOption;
    setInstructor(selectedOption);
    formik.setFieldValue('instructorId', value);
  };
  const handleSelectTypeClass = (selectedOption: any) => {
    const { value } = selectedOption;
    setTypeClassSelect(selectedOption);
    formik.setFieldValue('typeClassId', value);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    if (Number(idClass) > 0 && initValues.id > 0) {
      if (initValues.instructor?.id) {
        setInstructor({
          value: initValues.instructor?.id,
          label: initValues.instructor ? initValues.instructor?.first_name : '',
        });
        formik.setFieldValue('instructorId', initValues.instructor?.id);
      }
      if (initValues.typeClass?.id) {
        setTypeClassSelect({
          value: initValues.typeClass?.id,
          label: initValues.typeClass ? initValues.typeClass.name : '',
        });
        formik.setFieldValue('typeClassId', initValues.typeClass?.id);
      }
      formik.setFieldValue('classTime', initValues.classTime);
    }
  }, [initValues]);

  useEffect(() => {
    setMakeCatRequests(true);
  }, []);

  return (
    <Modal
      id="kt_modal_create_user"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        handleCloseModal(false);
      }}
      // onEntered={loadStepper}
    >
      <div className="modal-content">
        <div className="modal-header" id="kt_modal_add_user_header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleCloseModal(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_add_user_form"
            className="form"
            action="#"
            onSubmit={formik.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Nombre de la clase
                  </label>
                  {formik.touched.className && formik.errors.className && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formik.errors.className}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Nombre de la clase"
                  {...formik.getFieldProps('className')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="className"
                  autoComplete="off"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Tipo de Clase
                  </label>
                  {formik.touched.typeClassId && formik.errors.typeClassId && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formik.errors.typeClassId}</span>
                    </div>
                  )}
                </div>
                <Select
                  options={type_class}
                  styles={styleSelect}
                  menuPosition={'fixed'}
                  placeholder={'Seleccione'}
                  isSearchable={true}
                  noOptionsMessage={() => (
                    <span>No se encontraron registros</span>
                  )}
                  onChange={handleSelectTypeClass}
                  value={typeClassSelect}
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Instructor
                  </label>
                  {formik.touched.instructorId && formik.errors.instructorId && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formik.errors.instructorId}</span>
                    </div>
                  )}
                </div>
                <Select
                  options={dataInstructorSelect}
                  styles={styleSelect}
                  menuPosition={'fixed'}
                  placeholder={'Seleccione'}
                  isSearchable={true}
                  noOptionsMessage={() => (
                    <span>No se encontraron registros</span>
                  )}
                  onChange={handleSelect}
                  value={instructor}
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Limite de inscripciones
                  </label>
                  {formik.touched.registrationLimit &&
                    formik.errors.registrationLimit && (
                      <div className="fv-plugins-message-container text-danger w-50 ms-5">
                        <span role="alert">
                          {formik.errors.registrationLimit}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Limite de inscripciones"
                  {...formik.getFieldProps('registrationLimit')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="registrationLimit"
                  maxLength={4}
                  autoComplete="off"
                  inputMode="numeric"
                  pattern="\d*"
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Fecha y hora de la clase
                  </label>
                  {formik.touched.classTime && formik.errors.classTime && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formik.errors.classTime}</span>
                    </div>
                  )}
                </div>
                <input
                  type="datetime-local"
                  placeholder="Fecha de la clase"
                  className="form-control mb-3 mb-lg-0"
                  name="classTime"
                  autoComplete="off"
                  value={formatDateForInput(formik.values.classTime)}
                  onChange={handleDateChange}
                  onBlur={formik.handleBlur}
                  min={getCurrentDateTime()}
                />
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  handleCloseModal(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">
                    {idClass > 0 ? 'Actualizar' : 'Guardar'}
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateClassModal };
