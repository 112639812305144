
import { toast } from 'react-toastify';

export const SimpleAlert = (message: string, autoClose: number, type: string) => {

    let alert: any;
    switch (type) {
        case 'success':
            alert = toast.success(message, {
                position: "top-center",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
        case 'error':
            alert = toast.error(message, {
                position: "top-center",
                autoClose: autoClose,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            break;
    }
    return alert;
 }