import moment from 'moment';
import 'moment-timezone';

// Format hour as hh:mm a
export const formatHour = (
  date: string,
  timezone: string = 'local'
): string => {
  const momentDate = moment(date);
  if (timezone === 'local') {
    return momentDate.format('h:mm a');
  } else {
    return momentDate.tz(timezone).format('h:mm a');
  }
};

// Helper function to add leading zero
const addLeadingZero = (num: number): string =>
  num < 10 ? `0${num}` : `${num}`;

// Format date as dd/MM/yyyy
export const formatDate = (date: Date): string => {
  const day = addLeadingZero(date.getDate());
  const month = addLeadingZero(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatHourGrid = (date: string): string => {
  const timePart = date.split('T')[1].split('.')[0];
  const [hours, minutes, seconds] = timePart.split(':');
  let hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12;
  hour = hour ? hour : 12;
  const formattedTime = `${hour}:${minutes} ${ampm}`;

  return formattedTime;
};

// Format date and time as dd-MM-yy HH:mm
export const formatDateTime = (date: Date): string => {
  const day = addLeadingZero(date.getDate());
  const month = addLeadingZero(date.getMonth() + 1);
  const year = date.getFullYear().toString().slice(-2);
  const hours = addLeadingZero(date.getHours());
  const minutes = addLeadingZero(date.getMinutes());
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

// Format hour as hh:mm a
// export const formatHour = (date: string): string => {
//   const now = new Date(date);
//   const localHours = now.getUTCHours() + now.getTimezoneOffset() / 60;
//   const minutes = addLeadingZero(now.getUTCMinutes());
//   const ampm = localHours >= 12 ? 'PM' : 'AM';
//   const hours = localHours % 12 || 12; // Adjust hour to 12-hour format
//   const strHours = addLeadingZero(hours);
//   return `${strHours}:${minutes} ${ampm}`;
// };

export const dateFormatt = (date: string) => {
  const now = new Date(date);
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const day = String(now.getUTCDate()).padStart(2, '0');
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};

export const dateFormattTime = (date: string) => {
  const now = new Date(date);
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
  return formattedDate;
};

export const dateFormattYYYYMMDD = (date: string) => {
  const now = new Date(date);
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const day = String(now.getUTCDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const formatDateToISO = (dateStr: string) => {
  // Crear un objeto Date a partir de la cadena de entrada
  const date = new Date(dateStr);

  // Asegurarse de que el objeto Date sea válido
  if (isNaN(date.getTime())) {
    throw new Error('Fecha no válida');
  }

  // Obtener los componentes de la fecha y hora
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

  // Construir la cadena en el formato deseado
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};
