import axios from 'axios';

const API_URL = process.env.REACT_APP_API;

const getCurrentDateFormatted = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexados
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// export async function getValidatyReport(filters: any) {
//   const currentDay = getCurrentDateFormatted();
//   let initDate;
//   let endDate;

//   try {
//     if (filters) {
//       const { fechaInicio, fechaFin } = filters;
//       initDate = fechaInicio;
//       endDate = fechaFin;
//     }

//     const response = await axios.get(`${API_URL}/reports/validatyReport`, {
//       params: {
//         purchase_date_ini: initDate,
//         purchase_date_fin: endDate,
//       },
//     });

//     const data = response.data.doc.data.rows;
//     return data;
//   }
//    catch (error) {
//     console.error('Error fetching validity report:', error);
//     throw error;
//   }
// }

export async function getValidatyReport(filters: any) {
  try {
    let initDate = '';
    let endDate = '';
    if (filters) {
      const { fechaInicio, fechaFin } = filters;
      initDate = fechaInicio ? fechaInicio : '';
      endDate = fechaFin ? fechaFin : '';
    }
    const response = await axios.get(
      `${API_URL}reports/validatyReport?purchase_date_ini=${initDate}&purchase_date_fin=${endDate}`
    );
    const data = response.data.doc.data.rows;
    return data;
  } catch (error) {
    console.error('Error fetching validity report:', error);
    throw error;
  }
}
