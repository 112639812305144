import React, { useState, useEffect } from 'react';
import { dateFormatt } from '../../../../../rbts/helpers/FormatDate';
import { KTSVG, LoadingGrid, TableList } from '../../../../../rbts/helpers';
import { formattedCurrency } from '../../../../../rbts/helpers/FormatCurrency';
import { DownloadExcel } from '../helpers/downloadExcel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetAll } from '../hooks/VentasHook';
import moment from 'moment';

type Props = {};
let initSales: any = {
  fechaInicio: '',
  fechaFin: '',
};

const View: React.FC<Props> = () => {
  const [loadingView, setloadingView] = useState(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [filters, setFilters] = useState({
    fechaInicio: '',
    fechaFin: '',
  });

  const { data, loadingRequest } = useGetAll(reloadGrid, filters);

  // const formatDateForInput = (date: any) => {
  //   if (!date) return '';
  //   const d = new Date(date);
  //   const year = d.getFullYear();
  //   const month = String(d.getMonth() + 1).padStart(2, '0');
  //   const day = String(d.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };

  const formatDateForInput = (date: any) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD');
  };
  const handleDateChangeInicio = (event: any) => {
    const { value } = event.target;
    formikSales.setFieldValue('fechaInicio', value);
  };
  const handleDateChangeFin = (event: any) => {
    const { value } = event.target;
    formikSales.setFieldValue('fechaFin', value);
  };

  const download = () => {
    let columnsName = [
      'Fecha Venta', 
      'Nombre del Paquete', 
      'Monto', 
      'Cliente',
    ];
    let dataExcel: any = data.map((item) => {
      return {
        purchase_date: item.purchase_date
          ? new Date(item.purchase_date ?? '')
              .toISOString()
              .split('T')[0]
          : 'Sin fecha',
        // purchase_date: new Date(item.purchase_date ?? '')
        //   .toISOString()
        //   .split('T')[0],
        package: item.package.package_name,
        amount: item.amount,
        userPurchase: item.userPurchase.first_name,
      };
    });
    const response = {
      sheetName: 'Ventas',
      fileName: 'reporte_ventas.xlsx',
      title: 'Reporte de Ventas',
      columnsName: columnsName,
      columns: [
        {
          key: 'purchase_date',
          width: 20,
        },
        {
          key: 'package',
          width: 20,
        },
        {
          key: 'amount',
          width: 20,
        },
        {
          key: 'userPurchase',
          width: 10,
        },
      ],
      data: dataExcel,
    };
    DownloadExcel(response);
  };

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const columns: any = [
    {
      id: 'purchaseDate',
      name: <div className="fw-bolder text-muted me-5">Fecha Venta</div>,
      selector: (row: any) => row.purchase_date,
      cell: (row: any) =>
        row.purchase_date ? dateFormatt(row.purchase_date) : 'Sin registro',
      sortable: true,
    },
    {
      id: 'package',
      name: <div className="fw-bolder text-muted me-5">Nombre del Paquete</div>,
      selector: (row: any) => row.package?.package_name,
      cell: (row: any) => (
        <>
          {row.package ? (
            <div className="fw-bolder">{row.package?.package_name}</div>
          ) : (
            <div className="text-muted">Sin registro</div>
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'amount',
      name: <div className="fw-bolder text-muted me-5">Monto</div>,
      selector: (row: any) => Number(row.amount),
      cell: (row: any) => (
        <>
          {row.amount ? (
            <div className="text-gray-900 ">
              {formattedCurrency(row.amount)}
            </div>
          ) : (
            <div className="text-muted">Sin registro</div>
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'userPurchase',
      name: <div className="fw-bolder text-muted me-5">Cliente</div>,
      selector: (row: any) =>
        row.userPurchase ? row.userPurchase?.first_name : 'Sin registro',
      sortable: true,
    },
  ];

  const salesSchema = Yup.object().shape({
    fechaInicio: Yup.string()
      .required('Fecha de inicio es requerida.')
      .test(
        'is-valid-date',
        'Fecha de inicio no es una fecha válida.',
        (value) => moment(value, 'YYYY-MM-DD', true).isValid()
      )
      .test(
        'is-before-today',
        'Fecha de inicio no puede ser mayor a la fecha actual.',
        (value) => moment(value).isSameOrBefore(moment(), 'day')
      ),
    fechaFin: Yup.string()
      .required('Fecha fin es requerida.')
      .test('is-valid-date', 'Fecha fin no es una fecha válida.', (value) =>
        moment(value, 'YYYY-MM-DD', true).isValid()
      )
      .test(
        'is-after-fechaInicio',
        'Fecha fin no puede ser menor que fecha de inicio.',
        function (value) {
          const { fechaInicio } = this.parent;
          return moment(value).isSameOrAfter(moment(fechaInicio));
        }
      ),
    // .test(
    //   'is-before-today',
    //   'Fecha fin no puede ser mayor a la fecha actual.',
    //   (value) => moment(value).isSameOrBefore(moment(), 'day')
    // ),
  });

  const formikSales = useFormik({
    initialValues: initSales,
    validationSchema: salesSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setFilters(values);
    },
  });

  return (
    <>
      <div className="row gy-5 g-xl-8 salesPageView">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <form
              id="kt_search_sales_form"
              className="form"
              action="#"
              onSubmit={formikSales.handleSubmit}
            >
              <div className="card-header border-0 pt-5">
                <div className="align-items-start flex-column col-12">
                  <div className="col-12">
                    <div className="row header-grid-wrapper">
                      <div className="col-1">
                        <label className="fw-bold">Desde:</label>
                      </div>

                      <div className="col-3">
                        <input
                          placeholder="Nombre"
                          className="form-control"
                          type="date"
                          name="fechaInicio"
                          value={formatDateForInput(
                            formikSales.values.fechaInicio
                          )}
                          onChange={handleDateChangeInicio}
                        />
                        {formikSales.touched.fechaInicio &&
                          formikSales.errors.fechaInicio && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formikSales.errors.fechaInicio}
                              </span>
                            </div>
                          )}
                      </div>
                      <div className="col-1">
                        <label className="fw-bold">Hasta:</label>{' '}
                      </div>

                      <div className="col-3">
                        <input
                          placeholder="Nombre"
                          className="form-control"
                          type="date"
                          name="fechaFin"
                          value={formatDateForInput(
                            formikSales.values.fechaFin
                          )}
                          onChange={handleDateChangeFin}
                        />
                        {formikSales.touched.fechaFin &&
                          formikSales.errors.fechaFin && (
                            <div className="fv-plugins-message-container text-danger w-100 ms-5">
                              <span role="alert">
                                {formikSales.errors.fechaFin}
                              </span>
                            </div>
                          )}
                      </div>

                      <div className="buttons-wrapper col-4">
                        <button
                          type="submit"
                          className="btn btn-sm btn-light-primary"
                          data-bs-placement="top"
                          data-bs-trigger="hover"
                          title="Buscar"
                          id="kt_toolbar_primary_button"
                          onClick={() => {
                            // addPayment();
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen004.svg"
                            // className="svg-icon-excel"
                          />
                          Buscar
                        </button>

                        <a
                          className="btn btn-sm btn-light-success"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-trigger="hover"
                          title="Descargar Excel"
                          id="kt_toolbar_primary_button"
                          onClick={() => {
                            download();
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/files/fil029.svg"
                            className="svg-icon-excel"
                          />
                          Descargar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
