import React, { useState } from 'react';
import { KTSVG, SimpleAlert } from '../../../../../rbts/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { addTest, updateTest } from '../../../../service/catalog/Test';
import { useGetOne } from '../hooks/TestHook';
import { NumericFormat } from 'react-number-format';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  id_row: number;
};
const ClienteSchema = Yup.object().shape({
  tipo: Yup.string().required('Requerido.'),
  monto: Yup.number()
    .min(1, 'Monto es requerido.')
    .required('Monto es requerido.'),
  referencia: Yup.string()
    .max(200, 'No puede tener más de 200 caracteres')
    .required('Referencia es requerido.'),
});

const CreateModal: React.FC<Props> = ({ id_row, show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { initValues } = useGetOne(id_row);

  const formTest = useFormik({
    initialValues: initValues,
    validationSchema: ClienteSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updateTest(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formTest.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        } else {
          addTest(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              formTest.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        formTest.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formTest.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formTest.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Tipo
                  </label>
                  {formTest.touched.tipo && formTest.errors.tipo && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formTest.errors.tipo}</span>
                    </div>
                  )}
                </div>
                <select
                  className="form-select"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...formTest.getFieldProps('tipo')}
                >
                  <option value={'TIPO1'}>TIPO 1</option>
                  <option value={'TIPO2'}>TIPO 2</option>
                </select>
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Monto
                  </label>
                  {formTest.touched.monto && formTest.errors.monto && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formTest.errors.monto}</span>
                    </div>
                  )}
                </div>
                <NumericFormat
                  name="monto"
                  className="form-control mb-3"
                  value={formTest.values.monto}
                  decimalScale={2}
                  decimalSeparator="."
                  fixedDecimalScale
                  placeholder="$ 0.00"
                  prefix="$ "
                  thousandSeparator=","
                  onValueChange={(values) => {
                    const { floatValue } = values;
                    formTest.setFieldValue(
                      'monto',
                      floatValue ? floatValue : 0
                    );
                  }}
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Referencia
                  </label>
                  {formTest.touched.referencia && formTest.errors.referencia && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formTest.errors.referencia}</span>
                    </div>
                  )}
                </div>
                <input
                  placeholder="Referencia"
                  {...formTest.getFieldProps('referencia')}
                  className="form-control"
                  type="text"
                  name="referencia"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  formTest.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
