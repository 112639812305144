import { useState, useEffect } from 'react';
import { getApiKeysRelations } from '../../../service/catalog/Apikey';
import { ApiKeyModel } from '../models/ApiKeyModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (
  realoadGrid: number,
  filters: any
) => {
  const [data, setData] = useState<ApiKeyModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getApiKeysRelations(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { data, loadingRequest };
};