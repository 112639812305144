import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getCatalog } from '../../app/service/generic/generic';

export const useSelectComponentFormat = (code: string, execute: boolean) => {
  const [optionsSelect, setOptionSelect] = useState<any[]>([]);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getCatalog(code)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (data) {
        const filteredRows = data.rows.filter((row: any) => row.code !== 'STR');
        const selectOptions = filteredRows.map((option: any) => ({
          value: option.id,
          label: option.name,
          code: option.code,
        }));
        setOptionSelect(selectOptions);
      }
    };

    if (execute) {
      fetchPost();
    }
  }, [execute]);

  return { optionsSelect };
};
