import React, { useState, useEffect } from 'react';
import { KTSVG } from '../../../../rbts/helpers';
import { useSelectEmpresas } from '../../empresas/hooks/EmpresasHook';
import { useFormik } from 'formik';
import Select from 'react-select';

type Props = {
  initFilterValues: any;
  onCleanFilter: () => void;
  onSearchFilter: (values: any) => void;
};

const Filter: React.FC<Props> = ({
  initFilterValues,
  onSearchFilter,
  onCleanFilter,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { empresas } = useSelectEmpresas();
  const [filterValues, setFilterValues] = useState(initFilterValues);

  useEffect(() => {
    if (initFilterValues) {
      setFilterValues(initFilterValues);
    }
  }, [initFilterValues]);

  const handleEmpresas = (selectedOption: any) => {
    const id = selectedOption.value;
    setSelectedOption(selectedOption);
    formFilter.setFieldValue('company_id', Number(id));
  };

  const formFilter = useFormik({
    initialValues: filterValues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSearchFilter(values);
    },
  });

  return (
    <>
      <div className="card-title align-items-start flex-column">
        <a
          href="#/"
          className={
            formFilter.values.company_id > 0
              ? 'btn btn-sm btn-primary'
              : 'btn btn-sm btn-light-primary'
          }
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen031.svg"
            className="svg-icon-5 svg-icon-gray-500 me-1"
          />
          Filtrar
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
          data-kt-menu="true"
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bolder">Opciones de filtro</div>
          </div>

          <div className="separator border-gray-200"></div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formFilter.handleSubmit}
          >
            <div className="px-7 py-5">
              <div className="mb-5">
                <label className="form-label fw-bold">Empresa</label>
                <div>
                  <Select
                    options={empresas}
                    placeholder={'Seleccione'}
                    onChange={handleEmpresas}
                    value={selectedOption}
                    noOptionsMessage={() => {
                      return <span>No hay empresas</span>;
                    }}
                    styles={{
                      placeholder: (provided, state) => ({
                        ...provided,
                        color: '#181c32',
                      }),
                      indicatorSeparator: (provided, state) => ({
                        ...provided,
                        display: 'none',
                      }),
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        color: '#7E8199',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        border: '1px solid #e4e6ef',
                        borderRadius: '6px',
                        padding: '3px',
                        fontSize: '1.1rem',
                        boxShadow: 'none',
                        backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: '1.1rem',
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="reset"
                  className="btn btn-sm btn-white btn-active-light-primary me-2"
                  data-kt-menu-dismiss="true"
                  onClick={() => {
                    formFilter.resetForm();
                    setSelectedOption(null);
                    onCleanFilter();
                  }}
                >
                  Limpiar
                </button>

                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  data-kt-menu-dismiss="true"
                >
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { Filter };
