import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

interface Filters {
  birthdayDate?: number;
}

export async function getBirthdayReport(filters: Filters) {
  let birthday = 13;
  try {
    if (filters && filters.birthdayDate) {
      birthday = filters.birthdayDate;
    }
    const response = await axios.get(
      `${API_URL}reports/birthdayReport?month=${birthday}`
    );
    const data = response.data.doc.data.rows;
    return data;
  } catch (error) {
    console.error('Error fetching birthday report:', error);
    throw error;
  }
}
