import React, { FC } from 'react';

import { PageTitle, PageLink } from '../../../../rbts/layout/core';
import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [];

const VentasPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Reporte de Ventas</PageTitle>
      <View />
    </>
  );
};

export { VentasPage };
