import { useState, useEffect } from 'react'
import { getAllTest, getOneTest } from '../../../../service/catalog/Test'
import { useHistory } from 'react-router-dom'

export const useGetAll = (
    realoadGrid: number,
    filters: any
  ) => {
    const [data, setData] = useState<any[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        setLoadingRequest(0);
        const res = await getAllTest(filters)
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          setData(res.rows);
          setLoadingRequest(1);
        }
      };
      if (realoadGrid) {
        fetchPost();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realoadGrid, filters]);
    return { data, loadingRequest };
  };

export const useGetOne = ( id:number ) => {
    const initModel = {
        id: 0,
        fecha: new Date(),
        tipo: "TIPO1",
        monto: 0,
        referencia: '',
        company_id:0
    }
    const [ initValues, setInitValues ] = useState<any>(initModel);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const res = await getOneTest( id ).then().catch((error) => {
                history.push('/error/500')
            });
            if( res ){
                setInitValues(res);
            }
         };
         if( id ){
            fetchPost();
         } else {
            setInitValues(initModel);
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id ]);
    return { initValues }
}
