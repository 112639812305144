export const formattedCurrency = (currencyValue: number) =>
  new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(currencyValue);

export const formattCoin = (valorMoneda: string) => {
  const partes = valorMoneda.split(' ');
  const numero = parseFloat(partes[0]);
  const formatoMoneda = numero
    .toLocaleString('es-MX', {
      style: 'currency',
      currency: 'MXN',
    })
    .replace('$', '');
  return formatoMoneda + ' ' + partes[1];
};
