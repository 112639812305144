import axios from 'axios';
import moment from 'moment';

const API_URL = process.env.REACT_APP_API;

export async function getPayment(filters: any) {
  try {
    const response = await axios.get(`${API_URL}payment`);
    return response.data.doc.data;
  } catch (error) {
    console.log('ERROR: ', error);
  }
}

export async function addPayment(body: any) {
  const { folio, typePaymentId, userId, packageId, imagePay } = body;
  let formData = new FormData();

  const currentDateTime = moment().format('YYYY-MM-DDTHH:mm:ss[Z]');

  formData.append('imagePay', imagePay);
  formData.append('packageId', packageId);
  formData.append('clientId', userId);
  formData.append('typePaymentId', typePaymentId);
  formData.append('folio', folio);
  formData.append('purchaseDate', currentDateTime);

  const response = await axios({
    method: 'post',
    url: `${API_URL}purchase`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response;
}

export async function getDataPaymentById(idPayment: number) {
  const response = await axios.get(`${API_URL}payment/${idPayment}`);
  const data = response.data.doc.data;
  return data;
}

export async function getUsersSelect() {
  const response = await axios.get(`${API_URL}users`);
  const data = response.data.doc.data;
  return data;
}

export async function getPackageSelect() {
  const response = await axios.get(`${API_URL}package`);
  const data = response.data.doc.data;
  return data;
}
