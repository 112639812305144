import React, {useState} from 'react'
import { useParams } from "react-router-dom";
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import { restorePassword } from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'
import {useDispatch} from 'react-redux'
import { toast } from 'react-toastify';

export function ConfirmPassword(){
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    let { token } : { token:string } = useParams();
    const [visivility, setVisivility] = useState(false)
    const validateVisibility = ( ) => {
        setVisivility(!visivility);
    }
    const [visivilityConfirm, setVisivilityConfirm] = useState(false)
    const validateVisibilityConfirm = ( ) => {
        setVisivilityConfirm(!visivilityConfirm);
    }

    const confirmPasswordSchema = Yup.object().shape({
        password: Yup.string()
            .matches(
                /^(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,20}$/,
                "Formato de contraseña incorrecta"
            )
            .required('Contraseña es requerido.'),
        confirmPassword: Yup.string()
            .matches(
            /^(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,20}$/,
            "Formato de contraseña incorrecta"
            )
            .required('Contraseña es requerido.')
            .oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),
    })
    const initialValues = {
        password: '',
        confirmPassword: '',
    }
    const formik = useFormik({
        initialValues,
        validationSchema: confirmPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            setTimeout(() => {
                restorePassword(values.password, values.confirmPassword, token)
                .then(( res ) => {
                    const { accessToken } = res.data;
                    setLoading(false);
                    dispatch(auth.actions.login(accessToken))
                    toast(`Bienvenido!`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                })
                .catch(( err ) => {
                    let resMessageToast: string = ""
                    const { data: { message, details = null } } = err.response;
                    resMessageToast =`${message}`;
                    if( details.length ){
                        setStatus(`${details[0]?.message}`)
                    }
                    setLoading(false)
                    setSubmitting(false)
                    toast.error(resMessageToast, {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
            }, 1000)
         
        },
    })
    return(
        <form
        className='form w-100'
        onSubmit={ formik.handleSubmit }
        noValidate
        id='kt_confirm_form'
        >
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Restablecer contraseña</h1>
            </div>
            {
                formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
                )
            }
            <div className='fv-row mb-10'>
                <div className='d-flex flex-stack mb-2'>
                    <label className='form-label fw-bolder text-dark fs-6 mb-0'>Contraseña</label>
                </div>
                <div className={ clsx('position-relative') }>
                    <input
                    type={ visivility ? 'text' : 'password' }
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg',
                        // {
                        // 'is-invalid': formik.touched.password && formik.errors.password,
                        // },
                        // {
                        // 'is-valid': formik.touched.password && !formik.errors.password,
                        // }
                    )}
                    />
                    <span className={ clsx('btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2') } data-kt-password-meter-control={'visibility'} onClick={validateVisibility}>
                        {visivility ? (
                            <i className={ clsx('bi bi-eye fs-2') }></i>
                        ) : (
                            <i className={ clsx('bi bi-eye-slash fs-2') }></i>
                        )}
                    </span>
                </div>
                {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger mt-3'>
                            <span role='alert'>{formik.errors.password}</span>
                            </div>
                        </div>
                )}
            </div>
            <div className='fv-row mb-10'>
                <div className='d-flex flex-stack mb-2'>
                        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirmar contraseña</label>
                    </div>
                    <div className={ clsx('position-relative') }>
                        <input
                            type={ visivilityConfirm ? 'text' : 'password' }
                            autoComplete='off'
                            {...formik.getFieldProps('confirmPassword')}
                            className={clsx(
                                'form-control form-control-lg',
                            )}
                        />
                        <span className={ clsx('btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2') } data-kt-password-meter-control={'visibility'} onClick={validateVisibilityConfirm}>
                        {visivilityConfirm ? (
                            <i className={ clsx('bi bi-eye fs-2') }></i>
                        ) : (
                            <i className={ clsx('bi bi-eye-slash fs-2') }></i>
                        )}
                    </span>
                    </div>
                    {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger mt-3'>
                            <span role='alert'>{formik.errors.confirmPassword}</span>
                            </div>
                        </div>
                    )}
                    <div className='form-text mb-5'>
                        Las contraseña debe ser al menos de 8 caracteres, contener mayúscula, mínusculas, números y algún signo [@#$%^&-+=()]]
                    </div>
            </div>

            <div className='text-center'>
                <button
                type='submit'
                id='kt_confirm_form_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={ formik.isSubmitting || !formik.isValid }
                >
                {!loading && <span className='indicator-label'>Restablecer</span>}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                    Espere por favor...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
                </button>
            </div>
        </form>
    )

}