import React, { useEffect, useState, useRef } from 'react';
import { KTSVG, SimpleAlert } from '../../../../rbts/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addPackage, updatePackage } from '../../../service/paquetes/Paquetes';
import { PackageModel } from '../../../modules/auth/models/PackageModel';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';
import { useSelectComponentFormat, useGetOne } from '../hooks/paquetesHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
  idPackage: number;
};

const packagesSchema = Yup.object().shape({
  packageName: Yup.string()
    .max(
      40,
      'El Nombre del Paquete solo puede tener un máximo de 40 caracteres.'
    )
    .required('Nombre es requerido.'),
  price: Yup.number()
    .min(0.01, 'El Precio debe ser mayor a 0.')
    .required('Precio es requerido.'),
  classPackage: Yup.number()
    .min(1, 'Cantidad de Clases debe ser mayor a 0.')
    .required('Cantidad de Días es requerido.'),
  daysPackage: Yup.number()
    .min(1, 'Días Activos debe ser mayor a 0.')
    .required('Días Activos es requerido.'),
  status: Yup.number()
    .min(1, 'Selecciona una opción.')
    .required('Estatus es requerido.'),
});

const CreatePackageModal: React.FC<Props> = ({
  show,
  handleClose,
  title,
  idPackage,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { initValues } = useGetOne(idPackage);
  const [loading, setLoading] = useState(false);
  const [statusPackage, setStatusPackage] = useState<any | null>(null);
  const { optionsSelect: status_package } = useSelectComponentFormat(
    'PST',
    true
  );

  const changeStatus = (selectedOption: any) => {
    const { value } = selectedOption;
    setStatusPackage(selectedOption);
    formikPackages.setFieldValue('status', value);
  };

  const handleCloseModal = (updateGrid: boolean) => {
    formikPackages.resetForm();
    setStatusPackage(null);
    handleClose(true);
  };

  const handleFocus = () => {
    if (formikPackages.values.price === 0) {
      if (inputRef.current) {
        const input = inputRef.current;
        setTimeout(() => {
          input.setSelectionRange(1, 1);
        }, 0);
      }
    }
  };

  const packageValues: PackageModel = {
    id: 0,
    packageName: '',
    price: 0,
    classPackage: 0,
    daysPackage: 0,
    status: 0,
    statusPackage: {
      id: 0,
      code: '',
      name: '',
    },
  };

  const formikPackages = useFormik({
    initialValues: initValues,
    validationSchema: packagesSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          updatePackage(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              setLoading(false);
              handleCloseModal(false);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        } else {
          addPackage(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              setLoading(false);
              handleCloseModal(false);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });

  useEffect(() => {
    if (Number(idPackage) > 0 && initValues.id > 0) {
      if (initValues.statusPackage?.id) {
        setStatusPackage({
          value: initValues.statusPackage?.id,
          label: initValues.statusPackage?.name,
        });
        formikPackages.setFieldValue('status', initValues.statusPackage?.id);
      }
    }
  }, [initValues]);

  return (
    <Modal
      id="kt_modal_create_package"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        handleCloseModal(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header" id="kt_modal_add_package_header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleCloseModal(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_add_package_form"
            className="form"
            action="#"
            onSubmit={formikPackages.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y pe-7"
              id="kt_modal_add_package_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_package_header"
              data-kt-scroll-wrappers="#kt_modal_add_package_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Nombre</label>
                  {formikPackages.touched.packageName &&
                    formikPackages.errors.packageName && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">
                          {formikPackages.errors.packageName}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  placeholder="Nombre"
                  {...formikPackages.getFieldProps('packageName')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  autoComplete="off"
                  disabled={formikPackages.values.id > 0}
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Precio</label>
                  {formikPackages.touched.price && formikPackages.errors.price && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formikPackages.errors.price}</span>
                    </div>
                  )}
                </div>
                <NumericFormat
                  name="price"
                  className="form-control mb-3"
                  value={formikPackages.values.price}
                  decimalScale={2}
                  decimalSeparator="."
                  fixedDecimalScale
                  placeholder="$ 0.00"
                  prefix="$ "
                  maxLength={15}
                  thousandSeparator=","
                  disabled={formikPackages.values.id > 0}
                  onValueChange={(value) => {
                    const { floatValue } = value;
                    formikPackages.setFieldValue(
                      'price',
                      floatValue ? floatValue : 0
                    );
                  }}
                  onFocus={handleFocus}
                  getInputRef={(el: HTMLInputElement | null) => {
                    inputRef.current = el;
                  }}
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Cantidad de Clases
                  </label>
                  {formikPackages.touched.classPackage &&
                    formikPackages.errors.classPackage && (
                      <div className="fv-plugins-message-container text-danger w-50 ms-5">
                        <span role="alert">
                          {formikPackages.errors.classPackage}
                        </span>
                      </div>
                    )}
                </div>
                <NumericFormat
                  name="classPackage"
                  className="form-control mb-3"
                  value={formikPackages.values.classPackage}
                  fixedDecimalScale
                  maxLength={2}
                  disabled={formikPackages.values.id > 0}
                  onValueChange={(value) => {
                    const { floatValue } = value;
                    formikPackages.setFieldValue(
                      'classPackage',
                      floatValue ? floatValue : 0
                    );
                  }}
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Días Activos
                  </label>
                  {formikPackages.touched.daysPackage &&
                    formikPackages.errors.daysPackage && (
                      <div className="fv-plugins-message-container text-danger w-50 ms-5">
                        <span role="alert">
                          {formikPackages.errors.daysPackage}
                        </span>
                      </div>
                    )}
                </div>
                <NumericFormat
                  name="daysPackage"
                  className="form-control mb-3"
                  value={formikPackages.values.daysPackage}
                  fixedDecimalScale
                  maxLength={2}
                  disabled={formikPackages.values.id > 0}
                  onValueChange={(value) => {
                    const { floatValue } = value;
                    formikPackages.setFieldValue(
                      'daysPackage',
                      floatValue ? floatValue : 0
                    );
                  }}
                />
              </div>

              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Estatus</label>
                  {formikPackages.touched.status &&
                    formikPackages.errors.status && (
                      <div className="fv-plugins-message-container text-danger w-50 ms-5">
                        <span role="alert">{formikPackages.errors.status}</span>
                      </div>
                    )}
                </div>
                <Select
                  options={status_package}
                  menuPosition={'fixed'}
                  placeholder={'Seleccione'}
                  isSearchable={true}
                  noOptionsMessage={() => (
                    <span>No se encontraron registros</span>
                  )}
                  onChange={changeStatus}
                  value={statusPackage}
                  backspaceRemovesValue
                />
              </div>
            </div>
            <div className="text-center pt-10 pb-5">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-packages-modal-action="cancel"
                onClick={() => {
                  handleCloseModal(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-packages-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreatePackageModal };
