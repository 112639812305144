import { useState, useEffect } from 'react'
import { getCatalog, getCatalogGrid } from '../../../../service/catalog/Catalogs'
import { GenericoModel } from '../models/GenericoModel'
import { useHistory } from 'react-router-dom'

export const useGetGenerico = ( code:string ) => {
    
    const [ title, setTitle ] = useState('');
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getCatalog( code ).then().catch((error) => {
                history.push('/error/500')
            });
            if( data ){
                setTitle(data.info.name)
            }
         };
         fetchPost();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ code ]);
    return { title }
}

export const useGetAll = (
    code:string,
    realoadGrid: number,
    filters: any
  ) => {
    const [data, setData] = useState<GenericoModel[]>([]);
    const [loadingRequest, setLoadingRequest] = useState(0);
    const history = useHistory();
    useEffect(() => {
      const fetchPost = async () => {
        setLoadingRequest(0);
        const res = await getCatalogGrid(code, filters)
          .then()
          .catch((error) => {
            history.push('/error/500');
          });
        if (res) {
          setData(res.rows);
          setLoadingRequest(1);
        }
      };
      if (realoadGrid && code) {
        fetchPost();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [realoadGrid, code, filters]);
    return { data, loadingRequest };
  };



export const useSelectGeneric = ( code:string ) => {

    const [ catalog, setCatalog ] = useState<GenericoModel[]>([]);
    const history = useHistory()
    useEffect(() => {
        const fetchPost = async () => {
            const data = await getCatalog( code ).then().catch((error) => {
                history.push('/error/500')
            });
            if( data ){
                setCatalog(data.rows);
            }
         };
         fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ code ]);

    return { catalog }
}
