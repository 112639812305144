import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  GenericoModel,
  DataResponse,
} from '../../../modules/auth/models/ClassModel';
import { PaymentModel } from '../../../modules/auth/models/PaymentModel';
import { toast } from 'react-toastify';
import {
  getPayment,
  getDataPaymentById,
  getUsersSelect,
  getPackageSelect,
} from '../../../service/pagos/Pagos';
import { formattedCurrency } from '../../../../rbts/helpers/FormatCurrency';

export const useGetAll = (realoadGrid: number, filters: any) => {
  const [data, setData] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getPayment(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
  }, [realoadGrid]);
  return { data, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    typePaymentId: 0,
    amount: '',
    statusId: 0,
    userId: 0,
    packageId: 0,
    folio: '',
    imagePay: '',
  };
  const [initValues, setInitValues] = useState<PaymentModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getDataPaymentById(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
  }, [id]);

  return { initValues };
};

export const useGetClientSelect = () => {
  const [dataIClientSelect, setDataIClientSelect] = useState<GenericoModel[]>(
    []
  );

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data: DataResponse = await getUsersSelect();

        if (data) {
          const options = data.rows
            .filter((row) => row.roles.some((role) => role.code === 'CLI'))
            .map((cliente) => ({
              value: cliente.client?.id,
              label: `${cliente.first_name} ${cliente.father_last_name} ${cliente.mother_last_name}`,
            }));
          setDataIClientSelect(options);
        }
      } catch (error: any) {
        const resMessageToast =
          error.response?.data?.message || 'Error fetching data';
        toast.error(resMessageToast, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    };

    fetchPost();
  }, []);

  return { dataIClientSelect };
};

export const useGetPackageSelect = () => {
  const [dataIPackageSelect, setDataIPackageSelect] = useState<GenericoModel[]>(
    []
  );

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data: DataResponse = await getPackageSelect();

        if (data) {
          const options = data.rows
            .filter((row) => row.statusPackage && row.statusPackage.id === 9)
            .map((packageInfo) => {
              if (
                packageInfo.price !== undefined &&
                packageInfo.price !== null
              ) {
                const priceNumber = parseFloat(packageInfo.price);
                return {
                  value: packageInfo.id,
                  label: `${packageInfo.packageName} - ${formattedCurrency(
                    priceNumber
                  )}`,
                  price: formattedCurrency(priceNumber),
                };
              } else {
                return {
                  value: packageInfo.id,
                  label: `${packageInfo.packageName} - N/A`,
                  price: 'N/A',
                };
              }
            });
          setDataIPackageSelect(options);
        }
      } catch (error: any) {
        const resMessageToast =
          error.response?.data?.message || 'Error fetching data';
        toast.error(resMessageToast, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    };

    fetchPost();
  }, []);

  return { dataIPackageSelect };
};
