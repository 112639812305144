import * as ExcelJS from 'exceljs';
import { DownloadExcelModel } from '../../../../modules/auth/models/SalesModel';

export const DownloadExcel = async (options: DownloadExcelModel) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(options.sheetName);
  sheet.properties.defaultRowHeight = 20;
  // sheet.getRow(1).border = {
  //   top: { style: 'thick', color: { argb: '0000000' } },
  //   left: { style: 'thick', color: { argb: '000000' } },
  //   bottom: { style: 'thick', color: { argb: '0000000' } },
  //   right: { style: 'thick', color: { argb: '0000000' } },
  // };

  sheet.getRow(1).height = 36;
  sheet.getRow(1).alignment = {
    vertical: 'middle',
  };
  sheet.getRow(1).font = {
    name: 'Arial',
    family: 4,
    size: 16,
    bold: true,
    color: { argb: 'ffffff' },
  };
  sheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '1F497D' },
  };

  sheet.mergeCells('A1', 'H1');
  sheet.getCell('A1').value = options.title;

  sheet.getRow(2).height = 20;
  sheet.getRow(2).alignment = {
    vertical: 'middle',
  };
  sheet.getRow(2).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'A7A7A7' },
  };
  sheet.getRow(2).font = {
    name: 'Arial',
    family: 4,
    size: 11,
    bold: true,
    color: { argb: 'FFFFFF' },
  };

  sheet.getRow(2).values = options.columnsName;
  sheet.columns = options.columns;

  const datarows: any = options.data;

  datarows.forEach((seller: any, index: any) => {
    const row: any = {};
    for (const c of options.columns) {
      row[c.key] = seller[c.key];
    }
    sheet.addRow(row);
  });

  workbook.xlsx
    .writeBuffer()
    .then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const anchor = document.createElement('a');
      const url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = options.fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      URL.revokeObjectURL(url);
    })
    .catch((err) => console.log(err));
};
