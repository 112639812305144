/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../rbts/layout/core';
import InicioPage from './components/View';
const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {/* {intl.formatMessage({ id: 'MENU.DASHBOARD' })} */}
        Inicio
      </PageTitle>
      {/* <DashboardPage /> */}
      <InicioPage></InicioPage>
    </>
  );
};

export { DashboardWrapper };
