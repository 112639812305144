import React, { useState, useEffect } from 'react';
import { KTSVG, TableList } from '../../../../rbts/helpers';
import { MenuComponent } from '../../../../rbts/assets/ts/components/MenuComponent';
import { useGetAll } from '../hooks/paquetesHook';
import { CreatePackageModal } from '../_modals/CreatePackageModal';
import { formattedCurrency } from '../../../../rbts/helpers/FormatCurrency';
import { Dropdown } from 'bootstrap';

type Props = {};

const View: React.FC<Props> = () => {
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({});
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);
  const [loadingView, setloadingView] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [packageTitleModal, setPackageTitleModal] = useState('');
  const [idPackageSend, setIdPackageSend] = useState(0);

  const addPackage = () => {
    setReloadGrid(Math.random() * 40);
    setPackageTitleModal('Crear nuevo paquete');
    setVisivilityModal(!visivilityModal);
  };

  const updatePackage = (id: number) => {
    setReloadGrid(Math.random() * 40);
    setPackageTitleModal('Editar paquete');
    setVisivilityModal(!visivilityModal);
    setIdPackageSend(id);
  };

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);
  const dropdownRender = (info: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <a
                className="dropdown-item"
                onClick={() => updatePackage(info.id)}
              >
                Editar
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  };

  const columns: any = [
    {
      id: 'packageName',
      name: <div className="fw-bolder text-muted me-5">Nombre del Paquete</div>,
      selector: (row: any) => row.packageName,
      cell: (row: any) => (
        <>
          {row.packageName ? (
            <div className="fw-bolder">{row.packageName}</div>
          ) : (
            <div className="text-muted">Sin nombre</div>
          )}
        </>
      ),
      sortable: true,
      width: '200px',
    },
    {
      id: 'price',
      name: <div className="fw-bolder text-muted me-5">Precio</div>,
      selector: (row: any) => Number(row.price),
      cell: (row: any) => (
        <>
          {row.price ? (
            <div className="text-gray-900 ">{formattedCurrency(row.price)}</div>
          ) : (
            <div className="text-muted">Sin registro</div>
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'classPackage',
      name: <div className="fw-bolder text-muted me-5">Cantidad</div>,
      selector: (row: any) => row.classPackage,
      sortable: true,
    },
    {
      id: 'daysPackage',
      name: <div className="fw-bolder text-muted me-5">Días Activos</div>,
      selector: (row: any) => row.daysPackage,
      sortable: true,
    },
    {
      id: 'statusPackage',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      selector: (row: any) => row.statusPackage.name,
      cell: (row: any) => (
        <>
          {row.statusPackage.code === 'ACT' ? (
            <span className="badge badge-light-success w-75">Activo</span>
          ) : row.statusPackage.code === 'INA' ? (
            <span className="badge badge-light-danger w-75">Desactivado</span>
          ) : (
            <span className="badge badge-light w-75">Sin estatus</span>
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={() => {
                    setVisivilityModal(true);
                    addPackage();
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div>
            </div>
            <TableList data={data} columns={columns}></TableList>
          </div>
        </div>
      </div>
      {/* MODALS */}
      <CreatePackageModal
        idPackage={idPackageSend}
        title={packageTitleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setloadingView(true);
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
          setIdPackageSend(0);
        }}
      />
    </>
  );
};

export { View };
