import React from 'react';
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole } from '../../../helpers';

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;

  return (
    <>
      {userHasRole(['ADMIN'], userRoles) && (
        <AsideMenuItem
          to="/dashboard"
          icon="/media/icons/duotune/general/gen001.svg"
          title={'Inicio'}
          fontIcon="bi-app-indicator"
        />
      )}

      {/* {userHasRole(['ADMIN'], userRoles) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              Catálogos
            </span>
          </div>
        </div>
      )} */}
      {/* {userHasRole(['ADMIN'], userRoles) && (
        <AsideMenuItemWithSub
          icon="/media/icons/duotune/general/gen054.svg"
          fontIcon="bi-layers"
          to="/catalogos/"
          title="Catálogos"
          hasBullet={false}
        >
          <AsideMenuItem
            to="/catalogos/prueba"
            icon="/media/icons/duotune/finance/fin010.svg"
            title="Prueba"
            fontIcon="bi-app-indicator"
          />
        
          <div className="menu-item">
            <div className="menu-content pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Generales
              </span>
            </div>
          </div>
          <AsideMenuItem
            to="/catalogos/generico/TEST"
            title="Genérico"
            icon="/media/icons/duotune/abstract/abs009.svg"
          />
        </AsideMenuItemWithSub>
      )} */}
      {userHasRole(['ADMIN'], userRoles) && (
        <div className="menu-item">
          <div className="menu-content pt-5 pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              Administración
            </span>
          </div>
        </div>
      )}
      {userHasRole(['ADMIN'], userRoles) && (
        <>
          <AsideMenuItem
            to="/usuarios"
            title="Usuarios"
            icon="/media/icons/duotune/general/gen049.svg"
          />
          <AsideMenuItem
            to="/clases"
            title="Clases"
            icon="/media/icons/duotune/general/gen013.svg"
          />
          <AsideMenuItem
            to="/paquetes"
            title="Paquetes"
            icon="/media/icons/duotune/ecommerce/ecm009.svg"
          />
          <AsideMenuItem
            to="/pagos"
            title="Pagos"
            icon="/media/icons/duotune/finance/fin010.svg"
          />
          <AsideMenuItemWithSub
            icon="/media/icons/duotune/general/gen005.svg"
            fontIcon="bi-app-indicator"
            to="/reportes"
            title="Reportes"
            hasBullet={false}
          >
            <AsideMenuItem
              to="/reportes/ventas/"
              title="Ventas"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/reportes/cumpleanos/"
              title="Cumpleaños"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/reportes/vigencias/"
              title="Vigencias"
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          {/* <AsideMenuItem
            to="/empresas"
            title="Empresas"
            icon="/media/icons/duotune/communication/com001.svg"
          />
          <AsideMenuItem
            to="/api_keys"
            title="API keys"
            icon="/media/icons/duotune/general/gen047.svg"
          /> */}
        </>
      )}
    </>
  );
}
