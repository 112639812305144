import React, { useState, useEffect } from 'react';
import { dateFormatt } from '../../../../../rbts/helpers/FormatDate';
import { KTSVG, LoadingGrid, TableList } from '../../../../../rbts/helpers';
import { DownloadExcel } from '../helpers/downloadExcel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGetAll } from '../hooks/CumpleanosHook';
import Select from 'react-select';
import {
  styleSelect,
  styleSelectLeft,
} from '../../../../../rbts/helpers/SelectStyle';

type Props = {};
let initSales: any = {
  birthdayDate: 0,
};

const View: React.FC<Props> = () => {
  const [loadingView, setloadingView] = useState(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [monthSelect, setMonthSelect] = useState<any | null>(null);
  const [filters, setFilters] = useState({
    birthdayDate: 0,
  });

  const months = [
    {
      value: 1,
      label: 'Enero',
    },
    {
      value: 2,
      label: 'Febrero',
    },
    {
      value: 3,
      label: 'Marzo',
    },
    {
      value: 4,
      label: 'Abril',
    },
    {
      value: 5,
      label: 'Mayo',
    },
    {
      value: 6,
      label: 'Junio',
    },
    {
      value: 7,
      label: 'Julio',
    },
    {
      value: 8,
      label: 'Agosto',
    },
    {
      value: 9,
      label: 'Septiembre',
    },
    {
      value: 10,
      label: 'Octubre',
    },
    {
      value: 11,
      label: 'Noviembre',
    },
    {
      value: 12,
      label: 'Diciembre',
    },
  ];

  const { data, loadingRequest } = useGetAll(reloadGrid, filters);

  const download = () => {
    let columnsName = ['Fecha', 'Nombre', 'Rol'];
    let dataExcel: any = data.map((item) => {
      return {
        birthdate: item.birthdate
          ? new Date(item.birthdate ?? '').toISOString().split('T')[0]
          : 'Sin fecha',
        first_name: item.first_name,
        roles:
          item.roles && item.roles.length > 0 ? item.roles[0].name : 'Sin rol',
      };
    });
    const response = {
      sheetName: 'Cumpleaños',
      fileName: 'reporte_cumpleanos.xlsx',
      title: 'Reporte de Cumpleaños Clientes',
      columnsName: columnsName,
      columns: [
        {
          key: 'birthdate',
          width: 20,
        },
        {
          key: 'first_name',
          width: 20,
        },
        {
          key: 'roles',
          width: 20,
        },
      ],
      data: dataExcel,
    };
    DownloadExcel(response);
  };

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const columns: any = [
    {
      id: 'birthdate',
      name: <div className="fw-bolder text-muted me-5">Fecha</div>,
      selector: (row: any) => row.birthdate,
      cell: (row: any) =>
        row.birthdate ? dateFormatt(row.birthdate) : 'Sin registro',
      sortable: true,
    },
    {
      id: 'first_name',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      // selector: (row: any) =>
      //   row.first_name
      //     ? `${row.first_name} ${row.father_last_name}`
      //     : 'Sin registro',
      selector: (row: any) => row.first_name,
      cell: (row: any) => (
        <>
          {row.first_name ? (
            <div className="fw-bolder">{row.first_name} {row.father_last_name}</div>
          ) : (
            <div className="text-muted">Sin registro</div>
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'roles',
      name: <div className="fw-bolder text-muted me-5">Rol</div>,
      selector: (row: any) => {
        if (row.roles && row.roles.length > 0) {
          return row.roles[0].name;
        }
        return 'Sin registro';
      },
      sortable: true,
    },
  ];

  const salesSchema = Yup.object().shape({
    birthdayDate: Yup.number()
      .required('El Mes es requerido.')
      .min(1, 'Debe seleccionar un Mes.'),
  });

  const formik = useFormik({
    initialValues: initSales,
    validationSchema: salesSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setFilters(values);
    },
  });

  const handleSelectTypeClass = (selectedOption: any) => {
    const { value } = selectedOption;
    setMonthSelect(selectedOption);
    formik.setFieldValue('birthdayDate', value);
  };
  return (
    <>
      <div className="row gy-5 g-xl-8 salesPageView birthdayPageView">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <form
              id="kt_search_sales_form"
              className="form"
              action="#"
              onSubmit={formik.handleSubmit}
            >
              <div className="card-header border-0 pt-5">
                <div className="align-items-start flex-column col-12">
                  <div className="col-12">
                    <div className="row header-grid-wrapper">
                      <div className="col-1">
                        <label className="fw-bold fs-6">Mes</label>
                      </div>

                      <div className="col-4">
                        <Select
                          options={months}
                          styles={styleSelectLeft}
                          menuPosition={'fixed'}
                          placeholder={'Seleccione'}
                          isSearchable={true}
                          noOptionsMessage={() => (
                            <span>No se encontraron registros</span>
                          )}
                          onChange={handleSelectTypeClass}
                          value={monthSelect}
                          className="w-100 birthday_select"
                        />

                        {formik.touched.birthdayDate &&
                          formik.errors.birthdayDate && (
                            <div className="fv-plugins-message-container text-danger w-50 ms-5">
                              <span role="alert">
                                {formik.errors.birthdayDate}
                              </span>
                            </div>
                          )}
                      </div>

                      <div className="col-7 buttons-wrapper">
                        <button
                          type="submit"
                          className="btn btn-sm btn-light-primary"
                          data-bs-placement="top"
                          data-bs-trigger="hover"
                          title="Buscar"
                          id="kt_toolbar_primary_button"
                          onClick={() => {
                            // addPayment();
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen004.svg"
                            // className="svg-icon-excel"
                          />
                          Buscar
                        </button>

                        <a
                          className="btn btn-sm btn-light-success"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-trigger="hover"
                          title="Descargar Excel"
                          id="kt_toolbar_primary_button"
                          onClick={() => {
                            download();
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/files/fil029.svg"
                            className="svg-icon-excel"
                          />
                          Descargar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
