import clsx from 'clsx';
import React, { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { HeaderUserMenu } from '../../../partials';
// import {useLayout} from '../../core'
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const Topbar: FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        <div
          className={clsx(
            'cursor-pointer symbol',
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="hover"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <img src={user.profile_photo} crossOrigin="anonymous" alt="ad" />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

export { Topbar };
