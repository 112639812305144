import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getSalesReport(filters: any) {
  try {
    let initDate = '';
    let endDate = '';
    if (filters) {
      const { fechaInicio, fechaFin } = filters;
      initDate = fechaInicio ? `${fechaInicio}` : '';
      endDate = fechaFin ? `${fechaFin}` : '';
    }
    const response = await axios.get(
      `${API_URL}reports/purchaseReport?purchase_date_ini=${initDate}&purchase_date_fin=${endDate}`
    );
    const data = response.data.doc.data.rows;
    return data;
  } catch (error) {
    console.error('Error fetching sales report:', error);
    throw error;
  }
}
