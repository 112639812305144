import React from 'react';
import { Dropdown } from 'bootstrap';

const InicioPage = () => {
  document
    .querySelectorAll('[data-bs-toggle="dropdown"]')
    .forEach((dropdown) => {
      new Dropdown(dropdown);
    });
  return (
    <>
      <div className="d-flex justify-content-center">
        <img
          className="w-550px"
          src="/media/logos/logo-gym-power-home.svg"
          alt="gym-home"
        />
      </div>
    </>
  );
};
export default InicioPage;
