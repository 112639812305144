import axios from 'axios'
import { toAbsoluteUrl } from '../../../rbts/helpers'
const API_URL = process.env.REACT_APP_API


export async function obtenerPaquetes() {

    try {
        const response = await axios.get(`${API_URL}package`);
        return response.data.doc.data;
    } catch (error) {
        console.log("ERROR: ", error);
    }
  
  }

  export async function addPackage( body:any ) {

    const { packageName, price, classPackage, daysPackage, status } = body;
    const data = {
        packageName: packageName,
        price: price,
        classPackage: classPackage,
        daysPackage: daysPackage,
        status: status
    }
    const response =  await axios.post(`${API_URL}package/`, data);
    return response;

}

export async function updatePackage( body:any ) {

    const { id, packageName, price, classPackage, daysPackage, status } = body;
    const data = {
        packageName: packageName,
        price: price,
        classPackage: classPackage,
        daysPackage: daysPackage,
        status: status
    }
    const response = await axios.put(`${API_URL}package/${id}`, data);
    return response;

}

export async function getCatalog(code: string) {
    const response = await axios.get(`${API_URL}catalogs/${code}`);
    const data = response.data.doc.data;
    return data;
}

export async function getDataPackageById(idPackage: number) {
    const response = await axios.get(`${API_URL}package/${idPackage}`);
    const data = response.data.doc.data;
  
    return data;
  }