import React, { useEffect, useState } from 'react';
import { KTSVG, SimpleAlert } from '../../../../rbts/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addPayment } from '../../../service/pagos/Pagos';
import { useGetClientSelect, useGetPackageSelect } from '../hooks/pagosHook';
import Select from 'react-select';
import { styleSelect } from '../../../../rbts/helpers/SelectStyle';
import { useSelectComponentFormat } from '../../../../rbts/hooks/genericoHook';
import { PaymentModel } from '../../../modules/auth/models/PaymentModel';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
};

let initPayment: PaymentModel = {
  id: 0,
  typePaymentId: 0,
  typePaymentCode: '',
  amount: '',
  statusId: 0,
  userId: 0,
  packageId: 0,
  folio: '',
  imagePay: '',
};
const CreatePagosModal: React.FC<Props> = ({ show, handleClose, title }) => {
  const [showTypePayment, setShowTypePayment] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { dataIClientSelect } = useGetClientSelect();
  const { dataIPackageSelect } = useGetPackageSelect();
  const [clientSelect, setClientSelect] = useState<any | null>(null);
  const [packageSelect, setPackageSelect] = useState<any | null>(null);
  const [typePaymentSelect, setTypePaymentSelect] = useState<any | null>(null);
  const [makeCatRequests] = useState<boolean>(true);
  const { optionsSelect: type_payment } = useSelectComponentFormat(
    'PYM',
    makeCatRequests
  );

  const paymentSchema = Yup.object().shape({
    packageId: Yup.number()
      .required('Paquete es requerido.')
      .min(1, 'Debe seleccionar un paquete.'),
    typePaymentId: Yup.number()
      .required('Tipo de pago es requerido.')
      .min(1, 'Debe seleccionar un tipo de pago.'),
    userId: Yup.number()
      .required('Cliente es requerido.')
      .min(1, 'Debe seleccionar un cliente.'),
    folio: Yup.string()
      .max(50, 'El folio no puede tener más de 100 caracteres')
      .matches(
        /^[a-zA-Z0-9]*$/,
        'El folio solo puede contener números y letras'
      )
      .when('typePaymentCode', {
        is: 'TRF',
        then: Yup.string().required('Folio es requerido'),
      }),
    imagePay: Yup.mixed().when('typePaymentCode', {
      is: 'TRF',
      then: Yup.mixed().required('Comprobante de pago es requerido'),
    }),
  });

  const formik = useFormik({
    initialValues: initPayment,
    validationSchema: paymentSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        addPayment(values)
          .then((res) => {
            const {
              data: { message },
            } = res;
            SimpleAlert(message, 3000, 'success');
            setLoading(false);
            handleCloseModal(false);
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            if (details) {
              setStatus(`${details[0]?.message}`);
            }
            setLoading(false);
            setSubmitting(false);
            SimpleAlert(resMessageToast, 3000, 'error');
          });
      }, 1000);
    },
  });

  const handleCloseModal = (updateGrid: boolean) => {
    formik.resetForm();
    setClientSelect(null);
    setPackageSelect(null);
    setTypePaymentSelect(null);
    setShowTypePayment(false);
    handleClose(true);
  };

  const handleSelectClient = (selectedOption: any) => {
    const { value } = selectedOption;
    setClientSelect(selectedOption);
    formik.setFieldValue('userId', value);
  };

  const handleSelectPackage = (selectedOption: any) => {
    const { value } = selectedOption;
    setPackageSelect(selectedOption);
    formik.setFieldValue('packageId', value);
  };
  const handleSelectTypePayment = (selectedOption: any) => {
    const { value, code } = selectedOption;
    if (code === 'TRF') {
      setShowTypePayment(true);
    } else {
      setShowTypePayment(false);
    }
    setTypePaymentSelect(selectedOption);
    formik.setFieldValue('typePaymentId', value);
    formik.setFieldValue('typePaymentCode', code);
  };

  return (
    <Modal
      id="kt_modal_create_user"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px mh-"
      show={show}
      onHide={() => {
        handleCloseModal(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header" id="kt_modal_add_user_header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleCloseModal(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 h-600px">
          <form
            id="kt_modal_add_user_form"
            className="form"
            action="#"
            onSubmit={formik.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Paquete</label>
                  {formik.touched.packageId && formik.errors.packageId && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formik.errors.packageId}</span>
                    </div>
                  )}
                </div>
                <Select
                  options={dataIPackageSelect}
                  styles={styleSelect}
                  menuPosition={'fixed'}
                  placeholder={'Seleccione'}
                  isSearchable={true}
                  noOptionsMessage={() => (
                    <span>No se encontraron registros</span>
                  )}
                  onChange={handleSelectPackage}
                  value={packageSelect}
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">
                    Tipo de Pago
                  </label>
                  {formik.touched.typePaymentId && formik.errors.typePaymentId && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formik.errors.typePaymentId}</span>
                    </div>
                  )}
                </div>
                <Select
                  options={type_payment}
                  styles={styleSelect}
                  menuPosition={'fixed'}
                  placeholder={'Seleccione'}
                  isSearchable={true}
                  noOptionsMessage={() => (
                    <span>No se encontraron registros</span>
                  )}
                  onChange={handleSelectTypePayment}
                  value={typePaymentSelect}
                />
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Cliente</label>
                  {formik.touched.userId && formik.errors.userId && (
                    <div className="fv-plugins-message-container text-danger w-50 ms-5">
                      <span role="alert">{formik.errors.userId}</span>
                    </div>
                  )}
                </div>
                <Select
                  options={dataIClientSelect}
                  styles={styleSelect}
                  menuPosition={'fixed'}
                  placeholder={'Seleccione'}
                  isSearchable={true}
                  noOptionsMessage={() => (
                    <span>No se encontraron registros</span>
                  )}
                  onChange={handleSelectClient}
                  value={clientSelect}
                />
              </div>
              {!showTypePayment ? null : (
                <>
                  <div className="fv-row mb-7">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2">
                        Folio
                      </label>
                      {formik.touched.folio && formik.errors.folio && (
                        <div className="fv-plugins-message-container text-danger w-50 ms-5">
                          <span role="alert">{formik.errors.folio}</span>
                        </div>
                      )}
                    </div>
                    <input
                      placeholder="Folio"
                      {...formik.getFieldProps('folio')}
                      className={`form-control mb-3 mb-lg-0 ${
                        formik.touched.folio && formik.errors.folio
                          ? 'is-invalid'
                          : ''
                      }`}
                      type="text"
                      name="folio"
                      maxLength={50}
                      autoComplete="off"
                    />
                  </div>
                  <div className="fv-row mb-7">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2">
                        Comprobante de pago
                      </label>
                      {formik.touched.imagePay && formik.errors.imagePay && (
                        <div className="fv-plugins-message-container text-danger w-50 ms-5">
                          <span role="alert">{formik.errors.imagePay}</span>
                        </div>
                      )}
                    </div>
                    <input
                      type="file"
                      name="imagePay"
                      accept=".png, .jpg, .jpeg"
                      onChange={(event) => {
                        if (event.currentTarget.files) {
                          let file = event.currentTarget.files[0];
                          let reader = new FileReader();

                          reader.readAsDataURL(file);
                          formik.setFieldValue('imagePay', file);
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="text-center pt-15">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  handleCloseModal(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Guardar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreatePagosModal };
