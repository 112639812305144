import React from 'react';

type Props = {
  text: string;
};

const LoadingGrid: React.FC<Props> = ({ text = '' }) => {
  return (
    <div className="d-flex align-items-center" style={{ minHeight: '625px' }}>
      <div className="text-muted text-center fs-6 w-100 m-5">
        <span className="indicator-progress" style={{ display: 'block' }}>
          {text}
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </div>
    </div>
  );
};

export { LoadingGrid };
