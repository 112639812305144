import React, { FC } from 'react';

import { PageTitle, PageLink } from '../../../../rbts/layout/core';
import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [];

const VigenciasPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Reporte de Vigencias</PageTitle>
      <View />
    </>
  );
};

export { VigenciasPage };
