import React, { useState } from 'react';
import { KTSVG, SimpleAlert } from '../../../../rbts/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import { generateApiKey } from '../../../service/catalog/Apikey';
import { useSelectEmpresas } from '../../empresas/hooks/EmpresasHook';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
};

const CreateModal: React.FC<Props> = ({ show, handleClose, title }) => {
  const [loading, setLoading] = useState(false);
  const { empresas } = useSelectEmpresas();

  const changeEmpresa = (e: any) => {
    const id = e.target.value;
    formApiKey.setFieldValue('company_id', Number(id));
  };

  const initvalues = {
    company_id: 0,
    apikey: '',
  };

  const formApiKey = useFormik({
    initialValues: initvalues,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        formApiKey.values.company_id !== 0
          ? generateApiKey(values)
              .then((res) => {
                const { apikey } = res;
                setLoading(false);
                formApiKey.setFieldValue('apikey', apikey);
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message, details = null },
                } = err.response;
                resMessageToast = `${message}`;
                if (details.length) {
                  setStatus(`${details[0]?.message}`);
                }
                setLoading(false);
                setSubmitting(false);
                SimpleAlert(resMessageToast, 3000, 'error');
              })
          : SimpleAlert('Seleccione una empresa', 3000, 'error');
      }, 1000);
    },
  });
  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-650px"
      show={show}
      onHide={() => {
        formApiKey.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              formApiKey.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formApiKey.handleSubmit}
          >
            <div
              className="d-flex flex-column scroll-y pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{ default: false, lg: true }"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              {formApiKey.status && (
                <div className="alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formApiKey.status}
                  </div>
                </div>
              )}
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2">Empresa</label>
                  {formApiKey.touched.company_id &&
                    formApiKey.errors.company_id && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">{formApiKey.errors.company_id}</span>
                      </div>
                    )}
                </div>
                <select
                  className="form-select"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...formApiKey.getFieldProps('company_id')}
                  onChange={changeEmpresa}
                >
                  <option
                    value={0}
                    {...{
                      name: '',
                      code: '',
                    }}
                  >
                    Sin empresa
                  </option>
                  {empresas.map((item, index) => (
                    <option
                      key={index}
                      value={item.id}
                      {...{
                        name: item.name.toString(),
                        code: item.code.toString(),
                      }}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label className="fw-bold fs-6 mb-2">Api Key</label>
                </div>
                <div className="d-flex flex-xl-row flex-row-fluid">
                  <input
                    placeholder="Api Key"
                    {...formApiKey.getFieldProps('apikey')}
                    readOnly
                    className="form-control mb-3 mb-lg-0"
                    type="text"
                    name="name"
                    autoComplete="off"
                  />
                  <>
                    {formApiKey.values.apikey !== '' ? (
                      <div
                        style={{ marginLeft: 15, marginTop: 5 }}
                        className="card-toolbar"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-trigger="hover"
                        title="Copiar"
                      >
                        <a
                          href="#/"
                          className="btn btn-sm btn-light-primary"
                          id="kt_toolbar_primary_button"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              formApiKey.values.apikey
                            );
                            SimpleAlert('¡Llave copiada!', 1000, 'success');
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen028.svg"
                            className="svg-icon-5 svg-icon-gray-500 me-1"
                          />
                        </a>
                      </div>
                    ) : null}
                  </>
                </div>
              </div>
            </div>
            <>
              {formApiKey.values.apikey === '' ? (
                <div className="text-center pt-15">
                  <button
                    type="reset"
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                    onClick={() => {
                      formApiKey.resetForm();
                      handleClose(false);
                    }}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-kt-users-modal-action="submit"
                    disabled={loading}
                  >
                    {!loading && (
                      <span className="indicator-label">Generar Api Key</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              ) : (
                <div className="text-center pt-15">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      formApiKey.resetForm();
                      handleClose(true);
                    }}
                  >
                    Aceptar
                  </button>
                </div>
              )}
            </>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { CreateModal };
