import React, { FC } from 'react';

import { PageTitle, PageLink } from '../../../../rbts/layout/core';
import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [];

const BirthdayPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>Reporte de Cumpleaños Clientes</PageTitle>
      <View />
    </>
  );
};

export { BirthdayPage };
