import axios from 'axios';
import { formatDateToISO } from '../../../rbts/helpers/FormatDate';
import moment from 'moment-timezone';

const API_URL = process.env.REACT_APP_API;
export async function obtenerClases(filters: any) {
  try {
    const response = await axios.get(`${API_URL}class`);
    return response.data.doc.data;
  } catch (error) {
    console.log('ERROR: ', error);
  }
}
export async function addClass(body: any) {
  const { className, classTime, instructorId, registrationLimit, typeClassId } =
    body;
  let formatDate = classTime;
  if (classTime) {
    formatDate = formatDateToISO(classTime);
  }

  const data = {
    className: className,
    typeClassId: typeClassId,
    classTime: formatDate,
    instructorId: instructorId,
    registrationLimit: registrationLimit * 1,
  };
  const response = await axios.post(`${API_URL}class/`, data);
  return response;
}
export async function updateClass(body: any) {
  const {
    id,
    className,
    classTime,
    instructorId,
    registrationLimit,
    typeClassId,
  } = body;
  let formatDate = classTime;
  if (classTime) {
    formatDate = formatDateToISO(classTime);
  }

  const data = {
    className: className,
    typeClassId: typeClassId,
    classTime: formatDate,
    instructorId: instructorId,
    registrationLimit: registrationLimit * 1,
  };
  const response = await axios.put(`${API_URL}class/${id}`, data);
  return response;
}
export async function getDataClassById(idClass: number) {
  const response = await axios.get(`${API_URL}class/Id/${idClass}`);
  const data = response.data.doc.data;
  let classTime = data.classTime;
  let classDate = moment(classTime).utc();
  let updatedClassTime = classDate
    .add(6, 'hours')
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  data.classTime = updatedClassTime;

  return data;
}

// export async function getClassById(idClass: number) {
//   try {
//     const response = await axios.get(`${API_URL}class/Id/${idClass}`);
//     let data = response.data.doc.data;
//     let classData = {
//       ...data,
//       tipo_id: data.tipoCuenta ? data.tipoCuenta.id : 0,
//       vendedor_comercial_id: data.vendedorComercial
//         ? data.vendedorComercial.id
//         : 0,
//       industria_id: data.industria ? data.industria.id : 0,
//       tamano_id: data.tamano ? data.tamano.id : 0,
//       referido_id: data.referido ? data.referido.id : 0,
//     };

//     return classData;
//   } catch (error) {
//     console.log('ERROR: ', error);
//   }
// }

export async function getUsersSelect() {
  const response = await axios.get(`${API_URL}users`);
  const data = response.data.doc.data;
  return data;
}
