import React, { useState, useEffect } from 'react';
import { MenuComponent } from '../../../../../rbts/assets/ts/components';
import { KTSVG, LoadingGrid, TableList } from '../../../../../rbts/helpers';
import { Filter } from './Filter';
import { CreateModal } from '../_modals/CreateModal';
import { RemoveModal } from '../_modals/RemoveModal';
import { useGetAll } from '../hooks/TestHook';

const View: React.FC = () => {
  const [loadingView, setloadingView] = useState(true);
  const [idRow, setIdRow] = useState(0);
  const [idDelete, setIdDelete] = useState(0);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues, setInitFilterValues] = useState({
    nombre: '',
  });
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);
  const addRegister = () => {
    setTitleModal('Nuevo registro');
    setVisivilityModal(!visivilityModal);
  };
  const editRegister = (id: number) => {
    setIdRow(id);
    setTitleModal('Editar registro');
    setVisivilityModal(!visivilityModal);
  };
  const removeRegister = (id: number) => {
    setIdDelete(id);
    setVisivilityRemoveModal(!visivilityRemoveModal);
  };
  const onSearchFilter = (values: any) => {
    setloadingView(true);
    if (values) {
      setInitFilterValues(values);
    } else {
      setInitFilterValues({
        nombre: '',
      });
    }
    setReloadGrid(Math.random() * 40);
  };
  const onCleanFilter = () => {
    setloadingView(true);
    setInitFilterValues({
      nombre: '',
    });
    setReloadGrid(Math.random() * 40);
  };
  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
      setTimeout(() => {
        MenuComponent.reinitialization();
      }, 500);
    }
  }, [loadingRequest]);

  const columns: any = [
    {
      id: 'id',
      name: <div className="fw-bolder text-muted me-5">ID</div>,
      selector: (row: any) => row.id,
      sortable: true,
      width: '10%',
    },
    {
      id: 'tipo',
      name: <div className="fw-bolder text-muted me-5">Tipo</div>,
      selector: (row: any) => row.tipo,
      sortable: true,
      width: '30%',
    },
    {
      id: 'monto',
      name: <div className="fw-bolder text-muted me-5">Monto</div>,
      selector: (row: any) => row.monto,
      sortable: true,
      width: '30%',
    },
    {
      id: 'referencia',
      name: <div className="fw-bolder text-muted me-5">Referencia</div>,
      selector: (row: any) => row.referencia,
      sortable: true,
      width: '20%',
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
      width: '10%',
    },
  ];

  const dropdownRender = (row: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  editRegister(row.id);
                }}
              >
                Editar
              </a>
            </div>
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                data-kt-users-table-filter="delete_row"
                href="#/"
                onClick={() => {
                  removeRegister(row.id);
                }}
              >
                Eliminar
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              />
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={addRegister}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <CreateModal
        id_row={idRow}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setloadingView(true);
            setReloadGrid(Math.random() * 40);
          }
          setIdRow(0);
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateModal>
      <RemoveModal
        show={visivilityRemoveModal}
        id_row={idDelete}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setloadingView(true);
            setReloadGrid(Math.random() * 40);
          }
          setIdDelete(0);
          setVisivilityRemoveModal(!visivilityRemoveModal);
        }}
      ></RemoveModal>
    </>
  );
};

export { View };
