import React, { useState, useEffect } from 'react';
import { MenuComponent } from '../../../../../rbts/assets/ts/components';
import { KTSVG, LoadingGrid, TableList } from '../../../../../rbts/helpers';
import { DropdownFilter } from '../../../../../rbts/partials/content/dropdown/DropdownFilter';
import { CreateModal } from '../_modals/CreateModal';
import { RemoveModal } from '../_modals/RemoveModal';
import { GenericoModel } from '../models/GenericoModel';
import { useGetAll } from '../hooks/genericoHook';

type Props = {
  code_catalogo: string;
};

const View: React.FC<Props> = ({ code_catalogo }) => {
  const [loadingView, setloadingView] = useState(true);
  const [codeCatalog, setCodeCatalog] = useState(code_catalogo);
  const [visivilityRemoveModal, setVisivilityRemoveModal] = useState(false);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [register, setRegister] = useState<GenericoModel>({
    id: 0,
    code: '',
    name: '',
    order: 0,
  });

  // GRID
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [filterName, setFilterName] = useState('');
  const { data, loadingRequest } = useGetAll(
    codeCatalog,
    reloadGrid,
    filterName
  );

  useEffect(() => {
    if (code_catalogo) {
      setloadingView(true);
      setCodeCatalog(code_catalogo);
      setFilterName('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code_catalogo]);

  const addRegister = () => {
    setTitleModal('Agregar registro');
    setVisivilityModal(!visivilityModal);
  };
  const removeRegister = (row: any) => {
    setRegister(row);
    setVisivilityRemoveModal(!visivilityRemoveModal);
  };
  const handleFilter = (name: string) => {
    setloadingView(true);
    if (name) {
      setFilterName(name);
    } else {
      setFilterName('');
    }
    setReloadGrid(Math.random() * 40);
  };
  const handleFilterClean = () => {
    setloadingView(true);
    setFilterName('');
    setReloadGrid(Math.random() * 40);
  };
  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
      setTimeout(() => {
        MenuComponent.reinitialization();
      }, 500);
    }
  }, [loadingRequest]);

  const columns: any = [
    {
      id: 'codigo',
      name: <div className="fw-bolder text-muted me-5">Código</div>,
      selector: (row: any) => row.code,
      sortable: true,
      width: '10%',
    },
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.name,
      sortable: true,
      width: '40%',
    },
    {
      id: 'orden',
      name: <div className="fw-bolder text-muted me-5">Orden</div>,
      selector: (row: any) => row.order,
      sortable: true,
      width: '40%',
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
      width: '10%',
    },
  ];

  const dropdownRender = (row: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                data-kt-users-table-filter="delete_row"
                href="#/"
                onClick={() => {
                  removeRegister(row);
                }}
              >
                Eliminar
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column">
                <a
                  href="#/"
                  // className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                  className={
                    filterName
                      ? 'btn btn-sm btn-primary'
                      : 'btn btn-sm btn-light-primary'
                  }
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                  data-kt-menu-flip="top-end"
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Filtrar
                </a>
                <DropdownFilter
                  handleFilter={handleFilter}
                  valueFilter={filterName}
                  handleFilterClean={handleFilterClean}
                />
              </div>

              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={() => {
                    addRegister();
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <CreateModal
        title={titleModal}
        show={visivilityModal}
        code_catalogo={codeCatalog}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setloadingView(true);
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateModal>
      <RemoveModal
        show={visivilityRemoveModal}
        register={register}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setloadingView(true);
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityRemoveModal(!visivilityRemoveModal);
        }}
      ></RemoveModal>
    </>
  );
};

export { View };
