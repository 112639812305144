import React, { FC } from 'react';
import { PageTitle, PageLink } from '../../../rbts/layout/core';
import { View } from './components/View';

const breadCrumbs: Array<PageLink> = [];

const ApiKeyPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>API Keys</PageTitle>
      <View />
    </>
  );
};

export { ApiKeyPage };
