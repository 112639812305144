import axios from 'axios'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'
import * as auth from './redux/AuthRedux'

export function Logout() {

  const dispatch = useDispatch();

  useEffect(() => {

    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
      splashScreen.style.setProperty('display', 'flex')
    }
    
    const userLogout = async() => {

      const API_URL = process.env.REACT_APP_API;
      await axios.post(`${API_URL}logout`)
      .then(( res ) =>{
          dispatch(auth.actions.logout())
          if (splashScreen) {
            splashScreen.style.setProperty('display', 'none')
          }
      }).catch(( err ) => {
      });
  
    }
    userLogout();

  }, [dispatch])
  
  return (
    <Switch>
      <Redirect to='/auth/login' />
    </Switch>
  )
}
