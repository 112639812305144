import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  obtenerClases,
  getDataClassById,
  getUsersSelect,
} from '../../../service/clases/Clases';
import {
  ClassModel,
  GenericoModel,
  DataResponse,
} from '../../../modules/auth/models/ClassModel';
import { toast } from 'react-toastify';

export const useGetAll = (realoadGrid: number, filters: any) => {
  const [data, setData] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await obtenerClases(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { data, loadingRequest };
};

export const useGetOne = (id: number) => {
  const initModel = {
    id: 0,
    className: '',
    typeClassId: 0,
    classTime: '',
    instructorId: 0,
    registrationLimit: 0,
    registrationCurrent: 0,
  };
  const [initValues, setInitValues] = useState<ClassModel>(initModel);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const res = await getDataClassById(id)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setInitValues(res);
      }
    };
    if (id) {
      fetchPost();
    } else {
      setInitValues(initModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { initValues };
};
const formmatedDataToSelect = (data: any) => {
  const selectOptions = data.map((option: any) => ({
    value: option.id,
    label: option.nombre,
  }));

  return selectOptions;
};

export const useGetInstructorSelect = () => {
  const [dataInstructorSelect, setDataInstructorSelect] = useState<
    GenericoModel[]
  >([]);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data: DataResponse = await getUsersSelect();

        if (data) {
          const options = data.rows
            .filter((row) => row.roles.some((role) => role.id === 3))
            .map((instructor) => ({
              value: instructor.id,
              label: `${instructor.first_name} ${instructor.father_last_name} ${instructor.mother_last_name}`,
            }));

          setDataInstructorSelect(options);
        }
      } catch (error: any) {
        const resMessageToast =
          error.response?.data?.message || 'Error fetching data';
        toast.error(resMessageToast, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      }
    };

    fetchPost();
  }, []);

  return { dataInstructorSelect };
};
