import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getApiKeysRelations(
  filters: any
) {
  let search: string = '';
  if (filters) {
    if (filters.company_id > 0) {
      search += `company_id=${filters.company_id}`;
    }
  }
  const response = await axios.get(
    `${API_URL}service/auth${search}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function getApiKey(id: number) {
  const response = await axios.get(`${API_URL}service/auth/${id}`);
  const data = response.data.doc.data;
  return data;
}
export async function generateApiKey(body: any) {
  const { company_id } = body;
  const data = {
    company_id: company_id,
    vigente: true,
  };
  const response = await axios.post(`${API_URL}service/auth`, data);
  const result = response.data.doc.data;
  return result;
}
export async function removeRegisterApiKey(id: number) {
  const response = await axios.delete(`${API_URL}service/auth/${id}`);
  return response;
}
