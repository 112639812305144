import React, { useState, useEffect } from 'react';
import { MenuComponent } from '../../../../rbts/assets/ts/components/MenuComponent';
import { useGetAll } from '../hooks/pagosHook';
import { dateFormatt } from '../../../../rbts/helpers/FormatDate';
import { KTSVG, LoadingGrid, TableList } from '../../../../rbts/helpers';
import { CreatePagosModal } from '../_modals/CreatePagosModal';
import { formattedCurrency } from '../../../../rbts/helpers/FormatCurrency';

type Props = {};

const View: React.FC<Props> = () => {
  const [loadingView, setloadingView] = useState(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({});
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [paymentTitleModal, setPaymentTitleModal] = useState('');

  const addPayment = () => {
    setReloadGrid(Math.random() * 40);
    setPaymentTitleModal('Agregar pago');
    setVisivilityModal(!visivilityModal);
  };

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
      setTimeout(() => {
        MenuComponent.reinitialization();
      }, 500);
    }
  }, [loadingRequest]);

  const columns: any = [
    {
      id: 'purchase_date',
      name: <div className="fw-bolder text-muted me-5">Fecha Pago</div>,
      selector: (row: any) => row.purchase_date,
      cell: (row: any) =>
        row.purchase_date ? dateFormatt(row.purchase_date) : 'Sin registro',
      sortable: true,
    },
    {
      id: 'amount',
      name: <div className="fw-bolder text-muted me-5">Monto</div>,
      selector: (row: any) => Number(row.amount),
      cell: (row: any) => (
        <>
          {row.amount ? (
            <div className="text-gray-900 ">
              {formattedCurrency(row.amount)}
            </div>
          ) : (
            <div className="text-muted">Sin registro</div>
          )}
        </>
      ),
      sortable: true,
    },
    {
      id: 'typePayment.id',
      name: <div className="fw-bolder text-muted me-5">Tipo</div>,
      selector: (row: any) => row.typePayment?.name,
      cell: (row: any) =>
        row.typePayment ? row.typePayment?.name : 'Sin registro',
      sortable: true,
    },
    {
      id: 'userPayments.id',
      name: <div className="fw-bolder text-muted me-5">Cliente</div>,
      selector: (row: any) => row.userPayments.first_name,
      cell: (row: any) =>
        row.userPayments ? row.userPayments?.first_name : 'Sin registro',
      sortable: true,
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={() => {
                    addPayment();
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      {/* MODALS */}
      <CreatePagosModal
        title={paymentTitleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setloadingView(true);
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
        }}
      ></CreatePagosModal>
    </>
  );
};

export { View };
