import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { FallbackView } from '../../rbts/partials';
import { userHasRole } from '../../rbts/helpers';
import { UserModel } from '../modules/auth/models/UserModel';
import { RootState } from '../../setup';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { UsuariosPage } from '../pages/usuarios/Usuarios';
import { GenericoPage } from '../pages/catalogos/generico/Generico';
import { TestPage } from '../pages/catalogos/test';
import { ApiKeyPage } from '../pages/apiKey/ApiKey';
import { EmpresasPage } from '../pages/empresas/Empresas';
import { ClasesPage } from '../pages/clases/Clases';
import { PaquetesPage } from '../pages/paquetes/Paquetes';
import { PagosPage } from '../pages/pagos/Pagos';
import { VentasPage } from '../pages/reportes/ventas/Ventas';
import { BirthdayPage } from '../pages/reportes/birthday/Birthday';
import { VigenciasPage } from '../pages/reportes/vigencias/Vigencias';

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {/* DASHBOARD */}
        <Route
          path="/dashboard"
          component={
            userHasRole(['ADMIN'], userRoles) ? DashboardWrapper : ErrorsPage
          }
        />

        {/* CATALOGOS */}
        <Route
          path="/catalogos/prueba/"
          component={userHasRole(['ADMIN'], userRoles) ? TestPage : ErrorsPage}
        />
        <Route
          path="/catalogos/generico/:code"
          component={
            userHasRole(['ADMIN'], userRoles) ? GenericoPage : ErrorsPage
          }
        />
        {/* USUARIOS DEL SISTEMA */}
        <Route
          path="/usuarios"
          component={
            userHasRole(['ADMIN'], userRoles) ? UsuariosPage : ErrorsPage
          }
        />
        <Route
          path="/clases"
          component={
            userHasRole(['ADMIN'], userRoles) ? ClasesPage : ErrorsPage
          }
        />
        <Route
          path="/paquetes"
          component={
            userHasRole(['ADMIN'], userRoles) ? PaquetesPage : ErrorsPage
          }
        />
        <Route
          path="/pagos"
          component={userHasRole(['ADMIN'], userRoles) ? PagosPage : ErrorsPage}
        />
        <Route
          path="/reportes/ventas"
          component={
            userHasRole(['ADMIN'], userRoles) ? VentasPage : ErrorsPage
          }
        />
        <Route
          path="/reportes/cumpleanos"
          component={
            userHasRole(['ADMIN'], userRoles) ? BirthdayPage : ErrorsPage
          }
        />
        <Route
          path="/reportes/vigencias"
          component={
            userHasRole(['ADMIN'], userRoles) ? VigenciasPage : ErrorsPage
          }
        />
        <Route
          path="/empresas"
          component={
            userHasRole(['ADMIN'], userRoles) ? EmpresasPage : ErrorsPage
          }
        />
        <Route
          path="/api_keys/"
          component={
            userHasRole(['ADMIN'], userRoles) ? ApiKeyPage : ErrorsPage
          }
        />
        <Route path="/perfil" component={ProfilePage} />
        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="/error/404" />
      </Switch>
    </Suspense>
  );
}
