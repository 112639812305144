import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { MenuComponent } from '../../../../rbts/assets/ts/components/MenuComponent';
import {
  KTSVG,
  toAbsoluteUrl,
  LoadingGrid,
  TableList,
} from '../../../../rbts/helpers';
import { UserModel } from '../../../modules/auth/models/UserModel';
import { CreateUserModal } from '../_modals/CreateUserModal';
import { ActiveUserModal } from '../_modals/ActiveUserModal';
import { AddRolesUserModal } from '../_modals/AddRolesUserModal';
import { useGetAll } from '../hooks/userHook';
import { RootState } from '../../../../setup';
import { Dropdown } from 'bootstrap';

type Props = {};

let initUser: UserModel = {
  id: 0,
  alias: '',
  first_name: '',
  father_last_name: '',
  mother_last_name: '',
  email: '',
  phone: '',
  profile_photo: '',
  company: '',
  active: false,
  roles: [],
};

const View: React.FC<Props> = () => {
  const [loadingView, setloadingView] = useState(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({});
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);

  const [userSelected, setUserSelected] = useState<UserModel>(initUser);
  const [userTitleModal, setUserTitleModal] = useState('');
  const [activeTitleModal, setActiveTitleModal] = useState('');
  const [activeDescriptionModal, setActiveDescriptionModal] = useState('');
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityActiveModal, setVisivilityActiveModal] = useState(false);
  const [visivilityRolesModal, setVisivilityRolesModal] = useState(false);
  const [userCurrentId, setUserCurrentId] = useState(0);

  const userCurrent: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const activeUser = (id: number, active: boolean, user: any) => {
    if (active) {
      setActiveTitleModal('Desactivar');
      setActiveDescriptionModal(
        'Si confirma está acción el usuario no podrá acceder al sistema.'
      );
    } else {
      setActiveTitleModal('Activar');
      setActiveDescriptionModal(
        'Si confirma está acción el usuario podrá acceder al sistema.'
      );
    }
    setUserSelected(user);
    setVisivilityActiveModal(!visivilityActiveModal);
  };
  const updateUser = (id: number, user: any) => {
    setReloadGrid(Math.random() * 40);
    setUserSelected(user);
    setUserTitleModal('Editar usuario');
    setVisivilityModal(!visivilityModal);
  };
  const updateRoles = (id: number, user: any, userCurrentId: number) => {
    setReloadGrid(Math.random() * 40);
    setUserSelected(user);
    setVisivilityRolesModal(!visivilityRolesModal);
    setUserCurrentId(userCurrentId);
  };
  const addUser = () => {
    setReloadGrid(Math.random() * 40);
    setUserSelected(initUser);
    setUserTitleModal('Agregar usuario');
    setVisivilityModal(!visivilityModal);
  };

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
    // document
    //   .querySelectorAll('[data-bs-toggle="dropdown"]')
    //   .forEach((dropdown) => {
    //     new Dropdown(dropdown);
    //   });
  }, [loadingRequest]);

  // setTimeout(() => {
  //   MenuComponent.reinitialization();
  // }, 1000);

  const dropdownRender = (user: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => {
                  updateUser(user.id, user);
                }}
              >
                Editar
              </a>
            </li>
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => {
                  updateRoles(user.id, user, userCurrent.id);
                }}
              >
                Roles
              </a>
            </li>
            {userCurrent.id !== user.id && (
              <li>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    activeUser(user.id, user.active, user);
                  }}
                >
                  {user.active ? 'Desactivar' : 'Activar'}
                </a>
              </li>
            )}
          </ul>
        </div>
      </>
    );
  };

  const columns: any = [
    {
      id: 'id',
      name: '',
      selector: (row: any) => row.id,
      cell: (row: any) => (
        <>
          <div className="symbol symbol-40px me-5">
            <img
              src={
                row?.profile_photo
                  ? `${process.env.REACT_APP_PUBLIC}users/${row?.profile_photo}`
                  : toAbsoluteUrl('/media/avatars/blank.png')
              }
              crossOrigin="anonymous"
              className=""
              alt=""
            />
          </div>
        </>
      ),
      sortable: false,
      width: '100px',
    },
    {
      id: 'nombre',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.first_name,
      sortable: true,
      width: '200px',
    },
    {
      id: 'apellidos',
      name: <div className="fw-bolder text-muted me-5">Apellidos</div>,
      selector: (row: any) => row.father_last_name,
      cell: (row: any) => `${row.father_last_name} ${row.mother_last_name}`,
      sortable: true,
      width: '200px',
    },
    {
      id: 'correo',
      name: <div className="fw-bolder text-muted me-5">Correo</div>,
      selector: (row: any) => row.email,
      sortable: true,
      width: '300px',
    },
    {
      id: 'rol',
      name: <div className="fw-bolder text-muted me-5">Rol</div>,
      selector: (row: any) => row.email,
      cell: (row: any) => (
        <>
          {row.roles.map((rol: any, index: number) => (
            <span key={index} className="badge badge-light-primary">
              {rol.name}
            </span>
          ))}
        </>
      ),
      sortable: false,
      width: '250px',
    },
    {
      id: 'estatus',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      selector: (row: any) => row.active,
      cell: (row: any) => (
        <>
          {row.active ? (
            <span className="badge badge-light-success">Activo</span>
          ) : (
            <span className="badge badge-light-danger text-muted">
              Desactivado
            </span>
          )}
        </>
      ),
      sortable: true,
      width: '150px',
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
      width: '200px',
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={() => {
                    addUser();
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <CreateUserModal
        title={userTitleModal}
        show={visivilityModal}
        user={userSelected}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setloadingView(true);
            setReloadGrid(Math.random() * 40);
          }
          setUserSelected(initUser);
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateUserModal>
      <ActiveUserModal
        title={activeTitleModal}
        description={activeDescriptionModal}
        show={visivilityActiveModal}
        user={userSelected}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setloadingView(true);

            setReloadGrid(Math.random() * 40);
          }
          setUserSelected(initUser);
          setVisivilityActiveModal(!visivilityActiveModal);
        }}
      ></ActiveUserModal>
      <AddRolesUserModal
        userCurrentId={userCurrentId}
        show={visivilityRolesModal}
        id={userSelected.id}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setloadingView(true);
            setReloadGrid(Math.random() * 40);
          }
          setUserSelected(initUser);
          setVisivilityRolesModal(!visivilityRolesModal);
        }}
      ></AddRolesUserModal>
    </>
  );
};

export { View };
